/* Input CSS - Start */

//effecting partner hub css for input
// .mat-form-field-appearance-legacy .mat-form-field-infix{
//   padding: 0;
//   }
  
  
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label{  
    color: $text-status-error;
  }
  .DSA_Wb_custom-form-field .mat-mdc-form-field-error{
    color: $text-status-error;
     /*padding-left: 16px;*/
  }
  .DSA_Wb_custom-form-field.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple, 
  .DSA_Wb_custom-form-field.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{
    background-color: $text-status-error;
   
  }
  .DSA_Wb_custom-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-hint{
    font-size: $size-text-12;
    color: $text-neutral-80;
    padding-left: 16px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field.mat-form-field-disabled.mat-form-field-appearance-legacy .mat-form-field-label, 
  .DSA_Wb_custom-form-field.mat-form-field-disabled input.mat-input-element{
    color: $text-neutral-50;
  }  
  .DSA_Wb_custom-form-field.mBottom{
    margin: 0 0 24px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field.mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
    height: 2px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field:hover:not([disabled]) .mat-form-field-underline{
    background: $bg-neutral-80!important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field .mat-form-field-prefix, .DSA_Wb_custom-form-field .mat-form-field-suffix{
    color: $text-neutral-80;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field.mat-form-field-disabled .mat-form-field-prefix, 
  .DSA_Wb_custom-form-field.mat-form-field-disabled .mat-form-field-suffix{
    color: $text-neutral-50;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field.mat-form-field-disabled .mat-form-field-suffix i{
    opacity: 0.5;
  }
  .DSA_inputwithInfoCtr .icon{
    position: absolute;
    right: 16px;
    top:5px;
    z-index: 9;
  }
  
  .DSA_wb_txtArea_Icon{
  top: -3px!important;
  }
  .DSA_formicon{
    float: right;
  }
  .DSA_formicon:hover,.DSA_formicon:active,.DSA_formicon:focus{
    color:$blue-color !important;
  }
  .DSA_Wb_custom-form-field.DSA_wb_input-readonly{
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline{
      display: none;
    }
  }
  .DSA_Wb_custom-form-field textarea.mat-mdc-input-element{
    resize: none;
    padding:24px 12px 10px;
    background: $bggrey-lightwhite;
    border-radius: $border-radius-big $border-radius-big 0 0;
     transition: 0.2s all;
    //  margin: 0;
     &:hover:not([disabled]){
    background: $bg-white;
    @extend %input-hover-shadow;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline{
    background: $bg-neutral-80!important;
  }
    }
  
  }
  //effecting partner hub project
  // .mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  //   transform: translateY(-0.3em) scale(.75)!important;
  //   width: 133.33333%;
  // }
   
   .DSA_inputwithInfoCtr{
   position: relative;
   }
  
   .DSA_wb_mat-suffix {
      position: absolute;
      right: 16px;
      top: 15px;
  }
  .DSA_Wb_custom-form-field.DSA_form_nofloatLabel{
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label-wrapper{
      top: -32px;
    }
    .mat-mdc-input-element{
      height: 35px;
      padding: 8px;
      border-radius: $border-radius-small $border-radius-small 0 0;
      &:hover:not([disabled]){
        background: $bg-primary-50;
        box-shadow: none !important;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    &.mat-form-field-appearance-legacy .mat-form-field-label{
      padding: 0;
      font-size: $size-text-12;
      top:12px;
      color: $text-neutral-90;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label{
      display: block;
    }
    &.mat-form-field-appearance-legacy .mat-mdc-form-field-hint{
      padding-left: 0;
    }
    .mat-mdc-icon-button{
      width: 24px;
      height: 24px;
      line-height: 24px;
    }
    .DSA_wb_mat-suffix{
      top: 4px;
      right: 8px;    
    }
    &.DSA_form_suffixTxt{
      .DSA_wb_mat-suffix{
        top: 8px;
       }
    }
    
    .DSA_wb_mat-suffix i.icon{
      margin-top: 0;
    }  
    textarea.mat-mdc-input-element{
      height: auto;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    &.mat-form-field-appearance-legacy .mat-form-field-underline{
      display: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    &.mat-form-field-appearance-legacy.mat-focused .mat-form-field-underline{
      display: block;
    }
    &.mat-form-field-invalid .mat-mdc-input-element{
      background: $bgcolor-red;
      &:hover:not([disabled]){
        background: $bgcolor-red;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    &.mat-form-field-invalid.mat-form-field-appearance-legacy.mat-focused .mat-form-field-ripple{
      background: $text-status-error;
    }
    &.DSA_wb_input-readonly .mat-mdc-input-element{
      /*background: $bgcolor-white;*/
      padding: 8px 8px 8px 0;
      color: $text-neutral-90;
      &:hover:not([disabled]){
          background: $bggrey-lightwhite;
      }
    }
    &.DSA_form_suffixTxt{
      .mat-mdc-input-element{
        padding: 8px 118px 8px 8px;
      }
    }
    &.DSA_form_suffixIcon:not(.DSA_wb_input-readonly){
      .mat-mdc-input-element{
        padding: 8px 40px 8px 8px;
      }
    }
    &.mat-form-field-disabled .DSA_wb_mat-suffix, 
    &.mat-form-field-disabled .DSA_wb_mat-suffix i.icon{
      color: $text-neutral-50;
    }   
    
  }
  .DSA_inputwithInfoCtr_nofloatLabel{
      &.DSA_inputwithInfoCtr .icon{
          position:initial;
          right:0;
          top:auto;
      }
      .DSA_inputwithInfo{
          margin-right: 32px;
      }
  }
  
  .mat-form-field-hide-placeholder:not(.DSA_wb_custom-chip-list) input.mat-mdc-input-element::-webkit-input-placeholder{ 
    color: $text-neutral-60 !important;
    -webkit-text-fill-color: $text-neutral-60 !important;
  }
  .mat-form-field-hide-placeholder:not(.DSA_wb_custom-chip-list) input.mat-mdc-input-element:-ms-input-placeholder{ 
    color: $text-neutral-60 !important;
    -webkit-text-fill-color: $text-neutral-60 !important;
  } 
  .mat-form-field-hide-placeholder:not(.DSA_wb_custom-chip-list) input.mat-mdc-input-element::-ms-input-placeholder { 
    color: $text-neutral-60 !important;
    -webkit-text-fill-color: $text-neutral-60 !important;
  } 
  .mat-form-field-hide-placeholder:not(.DSA_wb_custom-chip-list) input.mat-mdc-input-element:-moz-placeholder{ 
    color: $text-neutral-60 !important;
    -webkit-text-fill-color: $text-neutral-60 !important;
  }   
  .mat-form-field-hide-placeholder:not(.DSA_wb_custom-chip-list) input.mat-mdc-input-element::-moz-placeholder{ 
    color: $text-neutral-60 !important;
    -webkit-text-fill-color: $text-neutral-60 !important;
  }
  .mat-form-field-hide-placeholder:not(.DSA_wb_custom-chip-list) input.mat-mdc-input-element::placeholder{
    color: $text-neutral-60 !important;
  }
  /* Input CSS - End */