.DSA_wb_custom-table{
    width: 100%;
    margin: 0 0 16px;
    thead{
        tr{
            th{
                font-family: $font-style-1;
                font-size: $size-text-14;
                font-weight: $font-500;
                font-style: normal;
                color: $text-black;
                padding: 0 8px 16px;
                vertical-align: top;
            }
            td{
                padding: 0 8px 16px;
                vertical-align: top;
            }
            th.th_sort{
                i.icon{
                    display: inline-block;
                    margin: 0 0 0 8px;
                    cursor: pointer;
                }        
            }
        }
    } 
    tbody{    
      border-radius: $border-radius-small;
        tr{
            td{
                font-family: $font-style-1;
                font-size: $size-text-14;
                font-weight: $font-400;
                font-style: normal;
                color: $text-black;
                padding: 8px;
                vertical-align: middle;
                border-top: 1px solid $bg-neutral-40;
                height: 40px;                 
                i, button{
                    display: inline-block;
                    visibility: hidden;
                }
                i ~ i{
                    margin: 0 0 0 24px;
                }
                .avatar-mini{
                    margin: 3px 16px 0 0;
                }
                .td_nonEditable{
                    display: block;
                    transition:display 0.2s ease 0.2s;
                }
                .td_editable{
                    display: none;
                    max-width: 220px;
                    transition:display 0.2s ease 0.2s;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                .mat-form-field-appearance-legacy .mat-form-field-wrapper{
                    padding: 0;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                .mat-form-field-subscript-wrapper, .mat-form-field-label-wrapper{
                    display: none;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                .mat-form-field-appearance-legacy .mat-form-field-underline{
                    bottom: 0;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
                .mat-form-field-appearance-legacy .mat-form-field-infix{
                    padding: 0;
                    border: none;
                }     
                &:not(:empty){

                    &:first-child{
                    border-top-left-radius: $border-radius-small;
                    border-bottom-left-radius: $border-radius-small;
                    }   
                }
                &:not(:empty){

                    &:last-child{
                        border-top-right-radius: $border-radius-small;
                        border-bottom-right-radius: $border-radius-small;
                    } 
                }   
            }
            &.highlight{
                background: $bg-primary-50;
            }
        }
        tr:first-child{
            td{
                border-top: 1px solid $bg-neutral-50;
            }
        }
        tr:last-child{
            td{
                border-bottom: 1px solid $bg-neutral-50;
            }
        }
        tr:hover{
            background: $bggrey-lightest;
            td{        
                i, button{
                    visibility: visible;
                    cursor: pointer;          
                }
            }   
        }
        tr.active{
            background: $bg-white;
            @extend %hover-shadow; 
            td{
                .td_editable{
                    display: block;
                    i{
                        visibility: visible;
                        cursor: pointer;          
                    }
                }
                .td_nonEditable{
                    display: none;
                }
            }
        }      
    } 
    &.noBor{
        border:0;
        tbody{    
            border-radius: $border-radius-small;
            tr{
                td{
                    border:0;
                }
            }
        }
    }    
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .DSA_wb_custom-table{
        tbody{
            tr:hover{   
                border-bottom: 1px solid $bg-neutral-50;  
            }
        }
    }     
}
@supports (-ms-ime-align:auto) {
    .DSA_wb_custom-table{
        tbody{
            tr:hover{   
            border-bottom: 1px solid $bg-neutral-50;  
            }
        }
    }     
}