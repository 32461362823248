/*custom tag select*/
.DSA_wb_custom-chip-list{
    width:100%;
    &:hover{
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-underline{
            background-color: $bg-neutral-80!important
        }
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label{
            color: $text-neutral-80!important
        }
    }
    /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
    .mat-mdc-chip.mat-mdc-standard-chip .mat-chip-remove{
        opacity: 1;
    }
    
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.DSA_wb_custom-chip-list.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $bg-neutral-50
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.DSA_wb_custom-chip-list.mat-mdc-form-field.mat-focused .mat-form-field-label{
    color:  $text-primary-80!important;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.DSA_wb_custom-chip-list.mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 1px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.DSA_wb_custom-chip-list.mat-mdc-form-field.mat-focused .mat-form-field-ripple{
    background-color: $bg-primary-80;
}    

.DSA_wb_custom-chip-list.DSA_form_nofloatLabel{
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label-wrapper{
        top:-32px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    &.mat-form-field-appearance-legacy .mat-form-field-label {
        padding: 0;
        font-size: $size-text-12;
        top: 12px;
        color: $text-neutral-90;
        display: block;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    &.mat-form-field-appearance-legacy .mat-form-field-underline{
        display: none;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    &.mat-form-field-appearance-legacy.mat-focused .mat-form-field-underline{
        display: block;
    }
}
/*custom tag select*/