body, html {
    font-family: $base-font;
    font-weight: $text-regular;
    font-size: $text-body;
    overflow-x: hidden;
    color:$grey-dark;
    background: $grey-lightwhite;
    line-height: 1;
    min-width: 360px;
  }

  .mat-mdc-button, .mat-mdc-fab, .mat-mdc-unelevated-button, .mat-mdc-icon-button, .mat-mdc-mini-fab, .mat-mdc-raised-button, .mat-mdc-outlined-button{
    font-family: $base-font !important;
    font-weight: 400 !important;
  }
 
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    padding:0;
    margin:0;
    line-height: 1;
  }
  p{
    padding:0;
    margin:0;
    line-height: 1.5;
  }
  ul,li{
    padding:0;
    margin:0;
    list-style: none;
  }

  //intap
  header.mainHdr{
    background: $color-dark;
    padding: 21px 16px;
    color: $white-color;
    font-family:$base-font;
    font-weight: 500;
    .logo{
      text-align: center;
      position: relative;
    }
    h1{
      font-size: $heading-md;   
      text-align: center;
      margin-bottom: 20px;      
    }
  
    .header-info-txt{
      color: $white-color;
      font-size:$text-lg;
      text-align: center;
      line-height: 19px;
      margin-bottom: 30px;
    }   
  }   
  .headerTitle{
    font-size: $text-md;
    font-weight: $text-bold;
    text-align: left;
    margin:0px 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .headerTitle.twoIcons{
    margin-right: 80px;
  }
  .headerTitle.threeIcons{
    margin-right: 120px;
  }
  
  .primary{
  @include btn-styles;
  
    &-btn{
      @extend .primary;
      background: $grey-dark;
      color:$white-color;
      border-radius: 8px !important;
      border:1px solid $grey-dark !important;
    }
    &-btn-dark{
      @extend .primary;
      background: transparent;
      color:$white-color;
      border: 1px solid $white-color;
      border-radius: 8px !important;
    }
  }
  .primary-btn:hover{
    @extend %raised-shadow;
   
  }
  .primary-btn:active{
    @extend %active-shadow;
    background: $grey-light;
   
  }
  .primary-btn:focus{
    outline: none !important;
  }
  .primary-btn:disabled,.primary-btn:disabled:active,.primary-btn:disabled:hover{
   box-shadow:none !important;
   background: $grey-lighter;  
   color:$white !important;
   border:1px solid $grey-lighter !important;
  }
  .secondary-btn{
    @extend .primary;
    color: $grey-dark;
    border:1px solid $grey-dark !important;  
    border-radius: 8px !important;
  }
  .secondary-btn:hover{
    @extend %raised-shadow;
    border:1px solid $grey-lighter !important; 
  }
  .secondary-btn:active{
    @extend %active-shadow;
    background: $grey-lightest;
    border:1px solid $grey-lighter !important; 
  }
  .secondary-btn:focus{
    outline: none !important;
  }
  .secondary-btn:disabled{
   color: $grey-lighter;
   border:1px solid $grey-lighter !important;    
   background: $white !important;
  }
  .secondary-btn:disabled, .secondary-btn:disabled:active, .secondary-btn:disabled:hover{
    box-shadow:none !important;
  }

  .text-btn{
    @extend .primary;    
    color: $color-dark;
    border-radius:0;
    background: none;
    min-width: auto !important;
    padding: 8px !important;
  }  
  .text-btn:hover{
    background: $grey-white;
  }
  .text-btn:active{   
    background: $color-lightest;
  }
  .text-btn:disabled{
   color: $grey-lighter;  
  }
  .text-btn:focus{
    outline: none !important;
  }
  .text-btn:disabled, .text-btn:disabled:active, .text-btn:disabled:hover{
    box-shadow:none !important;
  }
  .text-btn.Greywhite{
    color: $grey-white;
  }
  .text-btn.Greywhite:hover{
    color: $color-dark;
  }
  .text-btn.Greywhite:active{
    color: $color-dark;   
    background: $color-lightest;
  }  
  .text-btn.Greywhite:focus{
    outline: none !important;
  }
  .secondary-text-btn{
    @extend .primary;
    color: $grey-light;
    background: none;
    border-radius:0;
  }
  .secondary-text-btn:hover{
    @extend %raised-shadow;    
  }
  .secondary-text-btn:active{
    @extend %active-shadow;
  }
  .secondary-text-btn:focus{
    outline: none !important;
  }
  .secondary-text-btn:disabled{
   color: $grey-lighter; 
   
  }

  .tab-btn{
    @extend .primary;
    color: $grey-light;
    border-radius:8px;
    font-size: $text-xs;
    background: none;
    padding:5px 16px;
  }
  .tab-btn.active{
    background: $color-dark;
    color:$white;
    outline: none;
  }
  .tab-btn:disabled{
   color: $grey-lighter;  
  }

  .Icon-Btn{
    color:$color-dark;
    line-height: 24px  !important;
    display: inline-block;
    padding:4px 8px !important;
    background: none;

  }
  .Icon-Btn span{
    margin-right: 8px;
  }
  .Icon-Btn:hover{
    @extend %raised-shadow;
  }
  .Icon-Btn:active{
    @extend %active-shadow;
    background: $color-lightest;
  }
  .Icon-Btn:focus{
    outline: none !important;
  }

.fabButton{
    @include icon-circle-large;
    background: $color-dark;
    border:0;
    line-height: 56px;
     @extend %raised-shadow;
  }
  .fabButton i{
    margin-top: -8px;
  }
  .btnfullWdth{
    width:100%;
    text-align: center;
  }
  .btnShadow{
    @extend %default-shadow;
  }
.btnblock{
  width:100%;
}

  .content-section{
    padding: 16px;
    margin: 0 0 54px;
  }
  
  
  footer.mainFooter{
  
    box-shadow: 0 2px 10px rgba(0,0,0,0.20);
    min-height: 40px;
    
  
   
  
    a{

      height: 56px;
      font-size:$text-sm;
      font-family:$base-font;
      font-weight: 400;
      color: $grey-dark;
      text-align: center;
      padding-top:8px;
      &:hover{
        text-decoration: none;
        color: $grey-dark
      }
      &.active{
        color: $color-thick
      }
    }
    span{
      display: inline-block;
    }
  }
  .grid2Col{
    display: grid!important;
    grid-template-columns:50% 50% ;
  }
  .grid3Col{
    display: grid!important;
    grid-template-columns:33.33% 33.33% 33.33%;
  }
  .grid4Col{
    display: grid!important;
    grid-template-columns:25% 25% 25% 25% ;
  }
  .grid5Col{
    display: grid!important;
    grid-template-columns:20% 20% 20% 20% 20%;
  }
  .midbackground{
    background: $grey-lightest;
  
  }
  
  
  h2.mainh2{
    font-size:$heading-sm;
    color:$black-color;
    margin: 20px 0px;
    font-family: $base-font;
    
  }
  
  /*caurosel styles*/
  /*.roundBox{
    background: $white-color;
    border-radius:20px;
    height:40px;
    line-height: 40px;
    padding:0px 10px;
    font-size:$text-md;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(15,15,15,0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(15,15,15,0.3);
    box-shadow: 0px 0px 5px 0px rgba(15,15,15,0.3);
    margin:4px; 
    font-size:$text-md;
    font-family: $base-font;
      img{
      margin-right: 5px;
      width: auto !important;
      display: inline !important;
      vertical-align: middle;
      }
  }*/
  /*caurosel styles*/
  
 .fixedFooter{
    width:100%;
    position: fixed;
    bottom:0;
    left:0;
    background: $white-color;
    z-index: 1;
  }
  
  /* Card widget CSS - Start */
  .card-widget{
    @extend %default-shadow;    
    min-height: 50px;    
    .grid{
      display: grid;
      grid-template-columns:80% 20%;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    .mat-card-header-text{
      width: 100%;
      margin: 0;
    }
    hr{
      border-top: 1px solid $grey-lightest;
      margin: 0 -16px;  
    }  
    hr.marhr{
      margin: 16px 0;  
    }
    hr.nomar{
      margin: 0;  
    }
  }
  .card-widget.dark{
    background: $grey-dark;
  }
  .card-image-bg{    
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    border-radius: 4px;
  }
  .card-image-bg img{
    width: 100%;
    height: 100%;
  }
  .txt-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0,0,0,0.5);
    top:0;
    left: 0;
    border-radius: 4px;
    z-index: 1;
  }
  .txt_top{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    hr{      
      opacity:0.5;
    }
  }
  .txt_top > .mat-mdc-card-actions:last-child {
    margin-bottom: -8px;
    padding-bottom: 0;
}  
  .card-withRtImg .txt_left{
      margin-right:104px;
  }
  .card-withRtImg .img-cont{
    position: absolute;
    top: 0;
    right: 0;
  }
  .card-withLtImg .img-cont{
    position: absolute;
    top: 0;
    left: 0;
  }
  .card-widget.card-withRtImg, .card-widget.card-withTxt,.card-widget.card-withLtImg{
    min-height: 104px;
    //max-height: 104px;
  }
 
  .card-widget.card-withRtImg.card-lg,.card-widget.card-withLtImg.card-lg{
    min-height: 136px;
    max-height: 136px;
  }
  .card-withRtImg.card-lg .img_leader{
    margin: 12px 0 0;
  }
  .card-withRtImg .card-avatar{
    margin: -4px 0 0;
  }  
  .card-widget.mat-mdc-card.dark > .mat-mdc-card-actions:last-child{
    margin: 0;
    padding: 0;
  }
  .text-1line{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .text-2line{
    height: 42px;
    overflow: hidden;
  }
  .text-3line{
    height: 54px;
    overflow: hidden;
  }  
  .text-4line{
    height: 72px;
    overflow: hidden;
  }
  .h4Header.text-2line{
    height: 32px;
  }
  p.text-3line{
    height: 63px;    
  }
  .card-widget.card-square{
    min-width: 100px;
    max-width: 100px;
    float: left;     
  }
  .card-widget.card-square.square-lg{
    min-width: 156px;
    max-width: 156px;
  }
  .card-widget ~ .card-widget{
    margin: 0 0 0 32px;
  }
  .card-widget.card-withLtImg .txt_left{
    padding-left: 120px;
  }
  .card-widget .v-menu-icon{
    background-position: center;
  }
  .card-ul{
    list-style: none;
    padding: 0;
    li{
      padding: 8px 0;      
    }
    li:last-child{
      padding: 8px 0 0;
    }
    li.border-top{
      border-top: 1px solid $grey-lightest !important;
    }
  }  
  .action-ul{
    margin: 4px 0;
    li{
      float: left;
      margin: 0 0 0 8px;
    }
  }
  .card-fixed{
    max-width: 155px;
    min-width: 155px;
    height: 155px;
    float: left;
    .align-bottom{
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .card-widget.card-nobg{
    background: transparent;
    box-shadow: none !important;
    padding: 0;
  }
  .card-widget.card-withLtImg .txt_top .txt_left{
    padding-left: 72px;
    min-height: 56px;
  }    
  /* blogs-tile CSS - End */

  /* Datepicker CSS - Start */
  .custom-form-field{
      width: 100%;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.mat-form-field-appearance-legacy .mat-form-field-label{
    color: $grey-light;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.mat-form-field-appearance-legacy .mat-form-field-underline, .custom-form-field.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
    background: $grey-lighter;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.mat-mdc-form-field.mat-focused .mat-form-field-label{
    color: $grey-light;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.mat-mdc-form-field.mat-focused .mat-form-field-ripple{
    background: $color-dark;
    height: 1px;
  }
  .custom-form-field input.mat-mdc-input-element{
    color: $black-color;
    height:24px;
  }  
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.white.mat-form-field-appearance-legacy .mat-form-field-underline,
  .custom-form-field.white.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
    background: $grey-lighter;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.white.mat-mdc-form-field.mat-focused .mat-form-field-ripple{
    background: $grey-white;
    height: 1px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.white.mat-form-field-appearance-legacy .mat-form-field-label{
    color: $grey-white;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.white.mat-form-field-appearance-legacy .mat-form-field-infix{
    padding:4px 0
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.white .mat-form-field-label-wrapper{
    top:-0.5em;
    padding-top:-0.5em;
  }
  .custom-form-field.white input.mat-mdc-input-element{
    color: $grey-white;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.white.mat-form-field-appearance-legacy .mat-form-field-wrapper{
    margin-bottom: -4px;
  }

  .custom-form-field .mat-mdc-form-field-focus-overlay {
    background:none
  }
  
  
  .custom-form-field .mat-mdc-form-field-input-control  {
    letter-spacing: normal !important;
    padding-top: 8px;
  }
  
  
  .custom-form-field .mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
    padding: 0px !important;
    }
  
  
  .custom-form-field .mdc-text-field--no-label .mat-mdc-form-field-infix {
      //padding-top: 25px !important;
      padding-bottom: 8px !important;
      }
  
  .custom-form-field .mat-mdc-form-field-infix {
      padding-bottom: 0;
   }
  
  .custom-form-field .mdc-text-field, .DSA_wb_custom-chip-list .mdc-text-field { 
    padding:0
  }
  
  
  
  .custom-form-field .mat-mdc-icon-button .mat-mdc-button-ripple, .mat-mdc-icon-button .mat-mdc-button-persistent-ripple, .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    top: 6px !important;
    left: 7px !important;
    right: 0px !important;
    bottom:1px !important;
  }

  .custom-form-field .mat-mdc-icon-button{
    padding: 0;
    padding-top: 14px;
    padding-left: 18px;
    padding-bottom: 5px;
  }

  .custom-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label{
    color: $wb-color-dark!important;
    }
   .custom-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background:none !important;
    }
    .custom-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label , .DSA_wb_custom-chip-list .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label 
    { 
        top:37px !important
    }

    .custom-form-field  .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-133%) scale(0.75);
    }
  
    .custom-form-field .mat-mdc-icon-button .mat-ripple-element {
      background:none !important;
    }
 
  /*::placeholder { 
    color: $grey-white;;
    opacity: 1; 
  }
  
  :-ms-input-placeholder { 
    color:$grey-white;;
  }
  
  ::-ms-input-placeholder { 
    color: $grey-white;;
  }*/
  .mat-calendar-body-selected {
    background-color: $color-dark !important;
  }
  .mat-calendar .mat-calendar-header{
    background: $color-dark;
    padding: 16px 8px;
  }
  .mat-calendar .mat-calendar-controls{
    margin-top: 0;
    margin-bottom: 0;
  }
  .mat-calendar-arrow{
    border-top-color: $grey-white;
  }
  .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button, .mat-datepicker-toggle{
    color: $grey-white !important;
  }
  .mat-calendar-period-button{
    font-size: $heading-lg-md !important;
    color: $grey-white !important;
    letter-spacing: normal !important;
  }
  .mat-calendar .mat-calendar-table-header th{
    padding: 8px 0;
  }
  .mat-datepicker-content.mat-datepicker-content-touch .mat-calendar{
    max-width: 328px;
    max-height: 420px;
  }
  /* Datepicker CSS - End */
  /* Timepicker CSS - Start */
  .timepicker {
    .timepicker__header {
      background-color: $color-dark !important;
    }
    .timepicker-dial__control{
      font-size: $heading-lg;
      font-weight: 500;
    }
  
    .clock-face__clock-hand {
      background-color: $color-dark !important;
  
      &::after {
        background-color: $color-dark !important;
      }
  
      &::before {
        border-color: $color-dark !important;
      }
    }
  
    .clock-face__number {
      > span.active {
        background-color: $color-dark !important;
      }
    }
  
    button:not(.timepicker-dial__item) {
      color: $color-dark !important;
    }
  }
  /* Timepicker CSS - End */
  /* Checkbox CSS - Start */
  .custom-checkbox{
    margin: 0 16px 0 0;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox .mat-checkbox-inner-container{
    width: 24px;
    height: 24px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox .mat-checkbox-background, .custom-checkbox .mat-checkbox-frame{    
    border-color: $grey-light;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background, .custom-checkbox.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background{
    background: $color-dark;
    border: none;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox .mat-checkbox-checkmark-path{
    stroke: $white-color !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .custom-checkbox.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{
    background: $grey-lighter;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-checkmark-path{
    stroke: $grey-lightest !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox.mat-checkbox-disabled .mat-checkbox-background, .custom-checkbox.mat-checkbox-disabled .mat-checkbox-frame{
    border: none;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .custom-checkbox.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border: 2px solid $grey-lighter;
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.custom-checkbox .mat-checkbox-checkmark{
  fill: $grey-lightest;
}
  /* Checkbox CSS - End */
  /* Radio CSS - Start */
  /*.mat-radio-button ~ .mat-radio-button {
    margin-left: 16px;
  }*/
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .custom-radio .mat-radio-container{
    width: 24px;
    height: 24px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .custom-radio .mat-radio-outer-circle{
    width: 24px;
    height: 24px;
    border-color: $grey-light;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .custom-radio .mat-radio-inner-circle{
    width: 24px;
    height: 24px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .custom-radio .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: $color-dark;
  }
  .custom-radio .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle, .custom-radio .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .custom-radio .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .custom-radio .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
    background-color: $color-dark;
  }
  .custom-radio .mat-mdc-radio-button.mat-radio-disabled .mat-radio-outer-circle, .custom-radio .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle{
    border-color: $grey-lighter;
  }
  .custom-radio .mat-mdc-radio-button.mat-radio-disabled .mat-radio-label-content, .custom-radio .mat-radio-button.mat-radio-button.mat-radio-checked.mat-radio-button.mat-radio-disabled .mat-radio-label-content{
    color: $grey-lighter;
  }
  .custom-radio .mat-mdc-radio-button.mat-radio-disabled .mat-radio-inner-circle, .custom-radio .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element{
    background-color: $grey-lighter;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .custom-radio .mat-radio-label-content{
    color: $grey-light;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .custom-radio .mat-mdc-radio-button.mat-radio-checked .mat-radio-label-content{
    color: $grey-darkest;
  }

  .block-radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }
  
  .block-radio-button {
    margin: 5px;
  }
  /* Radio CSS - End */
  /* Slide toggle CSS - Start */
  .custom-slideToggle.mat-mdc-slide-toggle{
    display: block;
    margin: 0 0 16px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .custom-slideToggle .mat-slide-toggle-bar{
    background-color: $grey-light;
  }
  .custom-slideToggle .mat-mdc-slide-toggle-thumb, .custom-slideToggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
    background-color: $white-color;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .custom-slideToggle.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar{
    background-color: $color-dark;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .custom-slideToggle.mat-disabled .mat-slide-toggle-bar{
    background-color: $grey-lighter;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .custom-slideToggle.mat-disabled .mat-slide-toggle-thumb, .custom-slideToggle.mat-checked.mat-disabled .mat-slide-toggle-thumb{
    background-color: $grey-lighter;
    border: 1px solid $grey-light;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .custom-slideToggle.mat-mdc-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar{
    background-color: $color-lightest;
  }
  /* Slide toggle CSS - End */
  /* Input CSS - Start */
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label{
    color: $grey-light;
  }
  .custom-form-field .mat-mdc-form-field-error{
    color: $red-color;
  }
  .custom-form-field.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple, .custom-form-field.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{
    background-color: $red-color;
  }
  .custom-form-field.mat-form-field-appearance-legacy .mat-mdc-form-field-hint{
    font-size: $text-sm;
    color: $grey-light;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .custom-form-field.mat-form-field-disabled.mat-form-field-appearance-legacy .mat-form-field-label, .custom-form-field.mat-form-field-disabled input.mat-input-element{
    color: $grey-lighter;
  }
  .custom-form-field.mBottom{
    margin: 0 0 24px;
  }
  /* Input CSS - End */
  /* Select CSS - Start */
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .custom-form-field .mat-select-value{
    color: $black-color;
  }

  $icon_select: "%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bclip-path:url(%23a);%7D.c,.d%7Bfill:%237e7e7e;%7D.c%7Bopacity:0;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='24' height='24'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b'%3E%3Crect class='c' width='24' height='24'/%3E%3Cg transform='translate(180.3 -613.698) rotate(45)'%3E%3Crect class='d' width='9' height='2' rx='1' transform='translate(317.199 562.828)'/%3E%3Crect class='d' width='2' height='9' rx='1' transform='translate(326.234 564.793) rotate(180)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
  $icon_select_disabled: "%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bclip-path:url(%23a);%7D.c,.d%7Bfill:%23dadada;%7D.c%7Bopacity:0;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='24' height='24'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b'%3E%3Crect class='c' width='24' height='24'/%3E%3Cg transform='translate(180.3 -613.698) rotate(45)'%3E%3Crect class='d' width='9' height='2' rx='1' transform='translate(317.199 562.828)'/%3E%3Crect class='d' width='2' height='9' rx='1' transform='translate(326.234 564.793) rotate(180)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
    
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .custom-form-field .mat-mdc-select-arrow{
    border: none;
    width:24px;
    height: 24px;
    background-image: url("data:image/svg+xml, #{$icon_select}");
    margin-top: 10px;
  }
  .custom-form-field .mat-mdc-select-arrow svg {
    display: none;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .custom-select-option.mat-mdc-option .mat-option-text{
    font-size: $text-md;
    color: $grey-dark;
  }
  .custom-select-option.mat-mdc-option.mat-active{
    background: $grey-lightest;
  }
  .mat-mdc-select-panel .mat-mdc-optgroup-label, .mat-select-panel .custom-select-option.mat-mdc-option{
    height: 40px;
    line-height: 40px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled){
    color: $grey-dark;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-mdc-form-field .mat-mdc-select.mat-select-disabled .mat-select-arrow{
    background-image: url("data:image/svg+xml, #{$icon_select_disabled}");
  }
  .mat-primary .custom-select-option .mat-pseudo-checkbox-checked, .mat-primary .custom-select-option .mat-pseudo-checkbox-indeterminate{
    background-color: $color-dark;
  }
  /* Select CSS - End */

/*tab component styling*/
.custom_tab .mat-mdc-tab-header{
  border-bottom:1px solid $grey-lighter;
}
.custom_tab.mat-mdc-tab-group.mat-primary .mat-mdc-tab .mdc-tab-indicator__content--underline, .custom_tab.mat-mdc-tab-nav-bar.mat-primary .mat-mdc-tab .mdc-tab-indicator__content--underline{
  background-color: $color-dark;

}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.custom_tab .mat-tab-label .mat-tab-label-content{
  color:$grey-light;
  text-transform: uppercase;
  font-weight:500;
  font-size: $text-md;
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.custom_tab .mat-tab-label-active .mat-tab-label-content{
  color:$color-dark;
}

.norowMar{
  margin-left:0 !important;
  margin-right:0 !important;
}
.noleftPad{
  padding-left:0 !important;
}
.norightPad{
  padding-right:0 !important;
}


  /*Atom-Text*/
  .h1Header{
    font-size:$heading-lg;
    font-weight: $text-medium;
  }
  .h2Header{
    font-size:$heading-md;
    font-weight: $text-medium;
  }
  .h3Header{
    font-size:$heading-sm;
    font-weight: $text-medium;
  }
  .h4Header{
    font-size:$text-lg;
    font-weight: $text-medium;
  }
  .mainBodyTxt{
    font-size:$text-lg;
    font-weight: $text-regular;
  }
  .mainBodyTxtMedium{
    font-size:$text-lg;
    font-weight: $text-medium;
 
  }
  .mainBodyTxt2,.mainBtn_normal, .h5Header{
    font-size:$text-md;
    font-weight: $text-medium;
  }
  .mainBodyTxt3{
    font-size:$text-md;
    font-weight: $text-regular;

  }
  .mainBodyTxt4{
    font-size:$text-sm;
    font-weight: $text-regular;   
  }
  .mainBtn_small{
    font-size:$text-sm;
    font-weight: $text-medium;
  }
  .infoTxt{
    font-size:$text-xs;
    font-weight: $text-regular;
  }

  .linkStyle1{
    font-size:$text-md;
    font-weight: $text-medium;
    color:$yellow-color;
  }
  .linkStyle1:hover{
    color:$yellow-color;
  }
.linkStyle2{
    font-size:$text-md;
    font-weight: $text-regular;
    color:$color-dark;
}
.linkStyle2:hover, .linkStyle2:focus{
  color: $color-dark;
}
/* Forms - Feedback buttons */
.feedbackIcons_ul{
  list-style: none;
  li{
    margin: 0 32px 0 0;
    float: left;
    a{
      width: 24px;
      height: 24px;
      display: inline-block;
      cursor: pointer;
    }    
  }  
}
/* Forms - Icon Radio */
.iconRadio_ul{
  list-style: none;
  li{
    margin: 0 32px 0 0;
    float: left;
    position: relative;
    a{
      @extend %default-shadow;
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 50%;
      cursor: pointer;
    }
    a:hover{
      text-decoration: none;
    }    
    a::after{
      font-weight: 400;
      font-size: $text-xs;
      color: $grey-dark;
      position: absolute;
      bottom:-12px;
      width:100%;
      text-align: center;
      line-height: 14px;
   }   
  }
  li.active{   
    a::after{
      color: $color-dark;
    }
  }
  li.disabled{
    a{
      cursor: default;
    }    
    a::after{
      color: $grey-lighter;
    }
  }
}

/*atom-chip*/
.chipStyle{
  line-height: 16px;
  height:16px;
  padding:0px 8px;  
  font-size: $text-xs;
  border-radius:12px;
  display: inline-block;
  text-transform: uppercase;
}
.mat-mdc-chip.mat-mdc-standard-chip.chipStyle-Normal,
.mat-mdc-chip.mat-mdc-standard-chip.chipStyle-Big{
  background-color:$grey-white;
  height:24px;
  padding:0px 8px;
  line-height: 24px;
  font-size: $text-sm;
  border-radius:20px;
  display: inline-block;
  border:1px solid $grey-light;
  color:$black;
  min-height:24px;
}
.mat-mdc-chip.mat-mdc-standard-chip.chipStyle-Big{
  height:32px;
  line-height: 32px;
  min-height:32px;
}
.mat-mdc-chip.mat-mdc-standard-chip::after{
  background-color:$grey-white !important;
 
}
.mat-mdc-chip.mat-mdc-standard-chip.chipStyle-Normal.active,
.mat-mdc-chip.mat-mdc-standard-chip.chipStyle-Big.active{
  background-color:$color-lightest;
  border:1px solid $color-thick;
  color:$color-thick;
}
.mat-mdc-chip.mat-mdc-standard-chip.active::after{
  background-color:$color-lightest !important;
}
a.chipStyle{
  text-decoration: none;
}
.closedchip{
  border:1px solid $green-color;
  color:$green-color;
}
.inprogresschip{
  border:1px solid $yellow-color;
  color:$yellow-color;
}
.greychip{
  border:1px solid $grey-light;
  color:$grey-light;
}
.openchip{
  border:1px solid $red-color;
  color:$red-color;
}
.finalchip{
  border:1px solid $blue-color;
  color:$blue-color;
}
.whitechip{
  border:1px solid #fff;
  color:#fff;
}
.darkBG{
  background:#333;
  padding:10px;
  color:$white-color;
}
.selectionIcon{
  line-height: 1px;
}

.selectionIcon.icon-process-complete img{
  display:none;
}
.choiseChip .icon-process-complete.purple {
  background-image:url('../assets/images/icons/purple_icons/icon-process-complete-big.png') !important; 
}


/*list items*/
  
.listItemSingle {
  list-style: none;
  padding:0px;
  margin:0;
}
.listItemSingle li{
  padding:0px;
  margin:0 0 8px 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d; 
}
.listItemSingle li:last-child{
  margin: 0;
}
.listItemSingle li>span,.listItemSingle li>a{
  padding: 8px 16px;
  display: block;
  position: relative;
}
.listItemSingle li>a:hover{
  text-decoration: none;
  color:inherit;
}

.listItemSingle li.active>span,.listItemSingle li.active>a{
  background-color: $grey-lightestBg;
 
}
.listItemSingle li.active{
  background-color: $grey-lightest !important;
}
.blockInfo{
  display: block;  
}
.blockLabel{
  display: block;
  line-height: 8px;
  margin: 4px 0px 2px 0px;
}
.marginicon40{
  margin: 4px 0 0 0;
  display: block;
}
.vertical-RightCenter{
  @include verticalCenter;
  right:16px;
}
.vertical-LeftCenter{
  @include verticalCenter;
  left:16px;
}
.vertical-RightCenter0{
  @include verticalCenter;
  right:0px;
}
.vertical-LeftCenter0{
  @include verticalCenter;
  left:0px;
}
.textSingle{
  line-height: 40px;  
}
.textDouble{
  line-height: 20px;
}
.icontext{
  line-height: 10px;
}
.listLeft{
  width:65%;
  float: left;
  padding-right:8px;
  position: relative;
}
.listRight{
width:35%;
float: right;
text-align: right;
padding-left:8px;
}
.listBorder{
  border-bottom: 1px solid $grey-light;  
}
.supTxt{
  vertical-align: top;
}  
.chat-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  vertical-align: top;
  margin-left: -10px;
}  
.vTop{
  vertical-align: top;
} 
.inline-list li{
  display: inline-block;
  margin-right: 32px;
  vertical-align: middle;
}
.list-1line{
  line-height: 24px;
}
/*message bar*/
.mat-mdc-snack-bar-container{
  background: none;
  box-shadow:none;
  border-radius:none;
  padding:0;
}
.alert {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
  @extend %active-shadow;
}
.alert-success {
  color: $white;
  background-color: $green-color;
  border-color: $green-color;
  padding: 16px 50px;
}

.alert-danger {
  color: $white;
  background-color: $red-color;
  border-color: $red-color;
  padding: 16px 50px;
}
.alert-warning {
  color: $white;
  background-color: $yellow-color;
  border-color: $yellow-color;
  padding: 16px 50px;
}
.alert-info {
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 16px 50px;
}
.alert-black {
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 16px 50px 16px 16px;
}

.mutil-selector-wrap{
  display: flex;
   justify-content: space-between;

}

.mutil-selector-wrap>div{
  width: 50%;

}
.mutil-selector-wrap>div.wdth30{
  width: 30%;

}
.mutil-selector-wrap>div.wdth70{
  width: 70%;

}
 
.email-with-domain .custom-form-field{
width: 64%;
}
  
.email-with-domain:after{
  
  color: #e7e7e7;
  font-size: 16px;
  margin-left: 16px;
  content: "@infosys.com";
}
.header_bg{
  background: $color-dark;
  @extend %default-shadow;
}
.custom-tab .mdc-tab .mdc-tab__text-label{
  color: $grey-white;
  opacity: 0.5;
}
.custom-tab .mdc-tab--active .mdc-tab__text-label{
  color: $grey-white;
  opacity: 1;
  font-weight: $text-medium;  
}
.custom-tab .mdc-tab-indicator .mdc-tab-indicator__content--underline{
  border-color: $grey-white;
  margin: 0 8px;  
}
.custom-tab .mdc-tab{
  font-size: $text-md;
  font-weight: $text-regular;
  height: 56px;
  outline: none;
  padding: 0 8px;
}
.custom-tab .mdc-tab--active .tabIcon-Circle{
  background: $grey-white;
}
.tab-bar-icon .mdc-tab-indicator{
  display: none;
}
.tab-bar-icon .mdc-tab::before{
  content: url(../assets/images/icons/white_icons/icon-inprogress.png);
  position: absolute;
  left: -12%;
  top: 18px;
  width: 24px;
  height: 24px;
}
.tab-bar-icon .mdc-tab:first-child::before{
  content:'';
}
.custom-tab .sec-tab-bar{
  background: $grey-white;
  border-bottom: 2px solid $grey-lightest;
}
.custom-tab .sec-tab-bar .mdc-tab{
  height: 34px;
}
.custom-tab .sec-tab-bar .mdc-tab .mdc-tab__text-label{
  color: $grey-light;
  opacity: 1;
}
.custom-tab .sec-tab-bar .mdc-tab--active .mdc-tab__text-label{
  color: $color-dark;  
}
.custom-tab .sec-tab-bar .mdc-tab-indicator .mdc-tab-indicator__content--underline{
  border-color: $color-dark;
  margin: 0 8px;  
}
.custom-tab .scrollable-tab-bar .mdc-tab-scroller__scroll-content{
  padding: 0 24px;
}
/*chip css*/
.chipIconRight{
  margin-right: -5px !important;
  margin-left: 5px !important;
  float: right;
  margin-top: 4px !important;
}
.chipIconLeft{
  margin-left: -5px !important;
  margin-right: 5px !important;
  float: left;
  margin-top: 4px !important;
}

.chipIconRightBig{
  margin-right: -3px !important;
  margin-left: 5px !important;
  float: right;
  margin-top: 3px !important;
}
.chipIconLeftBig{
  margin-left: -3px !important;
  margin-right: 5px !important;
  float: left;
  margin-top:3px !important;
}
.choiseChip{
  cursor: pointer;
}
.designView.active, .codeView.active{
  display: block;
}
.designView.hide, .codeView.hide{
  display: none;
}

/*bottom sheet customisation*/
.mat-bottom-sheet-container,
.mat-bottom-sheet-container-large,
.mat-bottom-sheet-container-medium,
.mat-bottom-sheet-container-xlarge{
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  overflow: hidden !important;
}


  .scrollcontainer {
    position: relative;
    height: 300px;
  }


/*accordion customization*/
.cus-accordion .mat-expansion-panel-header{
  height:40px !important;
  padding:0 16px;
  font-size: $text-lg;
  border-radius:0;
  border-top-right-radius:4px;
  border-top-left-radius:4px;
}

.cus-accordion .mat-expansion-panel-header-title{
  color:$black;
}
.cus-accordion .mat-expansion-indicator::after,
.cus-accordion .mat-expansion-panel-header-description{
  color:$grey-light;
  
}
.cus-accordion .mat-expansion-panel.mat-expanded{
  background-color:$color-dark; 
}
.cus-accordion .mat-expanded .mat-expansion-panel-body{
  background-color:$white; 
}

.cus-accordion .mat-expanded .mat-expansion-panel-header-title,
.cus-accordion .mat-expanded .mat-expansion-indicator::after,
.cus-accordion .mat-expanded .mat-expansion-panel-header-description{
  color:$white;
 

}

.cus-accordion .mat-expansion-panel {
  margin: 16px 0 !important;
}

.cus-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  @extend %default-shadow;
}
.cus-accordion .mat-expanded.mat-expansion-panel:not([class*=mat-elevation-z]) {
  @extend %active-shadow;
}
.cus-accordion .mat-expansion-panel-body{
  padding:0;
}


/*expand collapse card*/
.card-widget.mat-mdc-card > .collapseAction.mat-mdc-card-actions:last-child{
  margin-bottom: -16px;
  padding:0;
}

.showmore{
  height:auto;
  overflow: auto;
}
.showless{
  height:120px;
  overflow: hidden;
}

/*hamburgermenu*/
.hamburgerMenuCtr{
  height:100vh;
  width:100vw;
  position: fixed;
  top:0;
  left:0;
  z-index: 2147483647;
  background: rgba(0,0,0,0.5);
  transform: translateX(-100%);
  transition: transform 0.4s;
}
.hamburgerMenuCtr.active{
 left:0;
 transform: translateX(0);
}
.hamburgerMenuInner{
  height:100vh;
  width:300px;
  background: $grey-lightwhite;
  position: relative;
}
.purplecard{
  min-width: 100%;
  background-color: $color-dark;
  box-shadow:none !important;
  color:$white;
  border-radius: 0 !important;
}
.bottomUtil{
  position: absolute;
  right:0;
  bottom:0;
  width: 100%;
}


.rightMenuCtr{
  height:100vh;
  width:100vw;
  position: fixed;
  top:0;
  right:0;
  z-index: 2147483647;
  background: rgba(0,0,0,0.5);
  transform: translateX(100%);
  transition: transform 0.4s;
}
.rightMenuCtr.active{
 right:0;
 transform: translateX(0);
}
.rightMenuInner{
  height:100vh;
  width:300px;
  background: $grey-lightwhite;
  position: relative;
  float:right;
}
.filterClose{
  margin-top:-5px;
}

.mat-drawer-container,
.mat-drawer-content{
  z-index: auto !important;
}


.card-widget .v-menu-icon.bottom{
  top: auto;
  bottom:16px;
  right: 8px;
  position: absolute;
}

.status{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
}
.mdc-tab-content{
  display: none;
}
.content--active{
  display: block;
}

/*timeline css*/
.timelineUL li{
  padding-bottom:8px;
  background: url('../assets/images/timelineConnector.png') repeat-y 12px 0px;
}
.timelineUL li i.hideBg{
  background-color: #fff;
  border-radius:50%;
}
.timelineUL li:first-child{
  background-position: 12px 32px;
  background-repeat: no-repeat;
}
.timelineUL li:last-child{
  background-position: 12px -19px ;
  background-repeat: no-repeat;
}
  
.timelineContent{
  margin-left: 32px;
}
.mat-divider.Greylightest{
  border-top-color:$grey-lightest;
}
/*timeline css*/

/*modal customization*/

.custom_modal .modal-dialog{
  @extend %raised-shadow;
  border:0;
  
}
.custom_modal .modal-header{
  padding:16px;
  border-bottom: 0;
}

.custom_modal .modal-body{
  padding:0px 16px 16px 16px;
}
.noheader.custom_modal .modal-body{
  padding:16px 16px 16px 16px;
}
.custom_modal .close span{
display: none;
}
.custom_modal .close{
    background: url(../assets/images/icons/default_icons/icon-close.png) no-repeat;
    width:24px;
    height:24px;
    padding:0;
    margin:0;

}
.modal-footer{
  padding:8px 16px;
  border-top: 1px solid $grey-lightest;
  justify-content: flex-start;
}
@media screen and (max-width:575px){
  .custom_modal .modal-dialog{
    margin:16px;    
  }
}

/*modal customization*/

.card-widget hr.whitedivider{
  border:0;
  border-top:1px solid $grey-white;
  margin:0;
  opacity: .25;
}
.profileList.listItemSingle li > span{
  background:none;
  padding: 8px 0;
}
.profileList{
  text-align: left;

}

/*.dropdown-menu {
  min-width:100%;
  padding: 0;
  left:-30px;
  margin: 0 0 0 -30px;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color:  $white;
  background-clip: padding-box;
  border: 0;
  border-radius: 0;
  width:100%;

}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 16px;
  clear: both;
  font-weight: 400;
  color: $grey-light;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item.active, .dropdown-item:active {
  color: $grey-light;
  text-decoration: none;
  background-color: $white;
}
.ngb-highlight{
  font-weight: 400 !important;
  color:$color-thick;
}
.heightDiv{
  min-height: 300px;
}*/
.typeaheadResult li{
  padding: 8px 16px;
  color: $grey-light;
  cursor: pointer;
}

.typeaheadResult li span{
  color:$color-thick;

}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
/* carousel with Bottom Nav */
.owl-theme .owl-dots .owl-dot span{        
  background: none;
  border:1px solid $color-dark;
  margin: 0 8px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
  background: $color-dark;
}
.owl-theme .owl-nav.disabled + .owl-dots{
  margin: 16px 0 0;
}
.owl-carousel.owl-drag .owl-item .card-widget{
  margin: 1px;
}
.owl-carousel.owl-drag .owl-item .card-widget.card-nobg{
  padding: 8px 0;
  margin: 0;
}
// .owl-carousel .owl-item img.fixedImg{
//   width: auto;
//   display: inline-block;
// }
.main-content{
  margin: 16px 0;
}
.icon-bg{
  background: $color-lighter;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}
.btn-cont button ~ button{
  margin: 0 0 0 16px;
}
.mat-bottom-sheet-container{
  font-size: 16px;
}
.circle-img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
}
.circle-img.blue{
  background: $blue-color;
}
.circle-img.green{
  background: $green-color;
}
.circle-img.red{
  background: $red-color;
}
.link-iconTxt{
  display: inline-block;
  line-height: 22px;
}


 //infy me or vendor designs LEDS
 header.DSA_mainHdr{
  background: $color-dark;
  padding: 21px 16px;
  color: $white-color;
  font-family:$base-font;
  font-weight: 500;
  .DSA_logo{
    text-align: center;
    position: relative;
  }
  h1{
    font-size: $heading-md;   
    text-align: center;
    margin-bottom: 20px;      
  }

  .DSA_header-info-txt{
    color: $white-color;
    font-size:$text-lg;
    text-align: center;
    line-height: 19px;
    margin-bottom: 30px;
  }   
} 

.DSA_headerTitle{
  font-size: $text-md;
  font-weight: $text-bold;
  text-align: left;
  margin:0px 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
}
.DSA_headerTitle.twoIcons{
  margin-right: 80px;
}
.DSA_headerTitle.threeIcons{
  margin-right: 120px;
}

.DSA_primary{
  @include btn-styles;
  
    &-btn{
      @extend .DSA_primary;
      background: $grey-dark;
      color:$white-color;
      border-radius: 8px !important;
      border:1px solid $grey-dark !important;
        &:hover{
          @extend %raised-shadow;
        
        }
        &:active{
          @extend %active-shadow;
          background: $grey-light;
         
        }
        &:focus{
          outline: none !important;
        }
        &:disabled,&:disabled:active,&:disabled:hover{
          box-shadow:none !important;
          background: $grey-lighter;  
          color:$white !important;
          border:1px solid $grey-lighter !important;
         }
    }
    &-btn-dark{
      @extend .DSA_primary;
      background: transparent;
      color:$white-color;
      border: 1px solid $white-color;
      border-radius: 8px !important;
    }
  }

.DSA_secondary-btn{
  @extend .DSA_primary;
  color: $grey-dark;
  border:1px solid $grey-dark !important;  
  border-radius: 8px !important;
    &:hover{
      @extend %raised-shadow;
      border:1px solid $grey-lighter !important; 
    }
    &:active{
      @extend %active-shadow;
      background: $grey-lightest;
      border:1px solid $grey-lighter !important; 
    }
    &:focus{
      outline: none !important;
    }
    &:disabled{
    color: $grey-lighter;
    border:1px solid $grey-lighter !important;    
    background: $white !important;
    }
    &:disabled, &:disabled:active, &:disabled:hover{
      box-shadow:none !important;
    }
}


.DSA_text-btn{
  @extend .DSA_primary;    
  color: $color-dark;
  border-radius:0;
  background: none;
  min-width: auto !important;
  padding: 8px !important;
    &:hover{
      background: $grey-white;
    }
    &:active{   
      background: $color-lightest;
    }
    &:disabled{
    color: $grey-lighter;  
    }
    &:focus{
      outline: none !important;
    }
    &:disabled, &:disabled:active, &:disabled:hover{
      box-shadow:none !important;
    }

    .Greywhite{
        color: $grey-white;
        &:hover{
          color: $color-dark;
        }
        &:active{
          color: $color-dark;   
          background: $color-lightest;
        }  
        &:focus{
          outline: none !important;
        } 
    }
   
}  

.DSA_secondary-text-btn{
  @extend .DSA_primary;
  color: $grey-light;
  background: none;
  border-radius:0;
    &:hover{
      @extend %raised-shadow;    
    }
    &:active{
      @extend %active-shadow;
    }
    &:focus{
      outline: none !important;
    }
    &:disabled{
    color: $grey-lighter; 
    
    }
    &:disabled, &:disabled:active, & :disabled:hover{
      box-shadow:none !important;
    }
}


.DSA_tab-btn{
  @extend .DSA_primary;
  color: $grey-light;
  border-radius:8px;
  font-size: $text-xs;
  background: none;
  padding:5px 16px;
    &.active{
      background: $color-dark;
      color:$white;
      outline: none;
    }
    &:disabled{
    color: $grey-lighter;  
    }
}


.DSA_Icon-Btn{
  color:$color-dark;
  line-height: 24px  !important;
  display: inline-block;
  padding:4px 8px !important;
  background: none;
    span{
      margin-right: 8px;
    }
    &:active{       
      background: $color-lightest;
    }
    &:focus{
      outline: none !important;
    }

}


.DSA_fabButton{
  @include icon-circle-large;
  background: $color-dark;
  border:0;
  line-height: 56px;
   @extend %raised-shadow;      
}
.DSA_btnfullWdth{
width:100%;
text-align: center;
}
.DSA_btnShadow{
@extend %default-shadow;
background: $white;
}
.DSA_btnblock{
width:100%;
}

.DSA_content-section{
  padding: 16px;
  margin: 0 0 54px;
}


footer.DSA_mainFooter{  
  box-shadow: 0 2px 10px rgba(0,0,0,0.20);
  min-height: 40px;

  a{

    height: 56px;
    font-size:$text-sm;
    font-family:$base-font;
    font-weight: 400;
    color: $grey-dark;
    text-align: center;
    padding-top:8px;
    &:hover{
      text-decoration: none;
      color: $grey-dark
    }
    &.active{
      color: $color-thick
    }
  }
  span{
    display: block;
  }
  span.DSA_infoTxt{
    margin: 4px 0 0;
  }
}
footer.DSA_fixedFooter{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.DSA_flexCol{
  display: flex;
}
.DSA_flexCol a{
  display: block;
  flex-grow: 1;
}

.DSA_midbackground{
  background: $grey-lightest;

}


h2.DSA_mainh2{
  font-size:$heading-sm;
  color:$black-color;
  margin: 20px 0px;
  font-family: $base-font;
  
}


.DSA_fixedFooter{
  width:100%;
  position: fixed;
  bottom:0;
  left:0;
  background: $white-color;
  z-index: 1;
}

/* Card widget CSS - Start */
.DSA_card-widget{
  @extend %default-shadow;    
  min-height: 50px;    
  .DSA_flex{
    display: flex;
    grid-template-columns:80% 20%;
  }  
  .DSA_flex80{
    flex-grow: 4;
  }
  .DSA_flex20{
    flex-grow: 1;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  .mat-card-header-text{
    width: 100%;
    margin: 0;
  }
  hr{
    border-top: 1px solid $grey-lightest;
    margin: 0 -16px;  
  }  
  hr.DSA_marhr{
    margin: 16px 0;  
  }
  hr.nomar{
    margin: 0;  
  }
}
.DSA_card-widget.dark{
  background: $grey-dark;
}
.mat-mdc-card-image.custom-card-image-bg{
    margin-bottom: -16px;
}
.DSA_card-image-bg{    
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  border-radius: 4px;
}
.DSA_card-image-bg img{
  width: 100%;
  height: 100%;
}
.DSA_txt-overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.5);
  top:0;
  left: 0;
  border-radius: 4px;
  z-index: 1;
}
.DSA_txt_top{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  hr{      
    opacity:0.5;
  }
}
.DSA_txt_top > .mat-mdc-card-actions:last-child {
  margin-bottom: -8px;
  padding-bottom: 0;
}  
.DSA_card-withRtImg .DSA_txt_left{
    margin-right:106px;
}
.DSA_card-withRtImg .DSA_txt_left.txt-withAvatar{
  margin-right: 96px;
}
.DSA_card-withRtImg .img-cont{
  position: absolute;
  top: 0;
  right: 0;
}
.DSA_card-withLtImg .img-cont{
  position: absolute;
  top: 0;
  left: 0;
}
.mat-mdc-card:not([class*="mat-elevation-z"]){
  @extend %default-shadow;
}
.DSA_card-widget.DSA_card-withRtImg, .DSA_card-widget.card-withTxt,.DSA_card-widget.DSA_card-withLtImg{
  min-height: 106px;
}

.DSA_card-widget.DSA_card-withRtImg.card-lg,.DSA_card-widget.DSA_card-withLtImg.card-lg{
  min-height: 136px;
  max-height: 136px;
}
.DSA_card-withRtImg.card-lg .img_leader{
  margin: 12px 0 0;
}
.DSA_card-withRtImg .card-avatar{
  margin: -4px 0 0;
}  
.DSA_card-widget.mat-mdc-card.dark > .mat-mdc-card-actions:last-child{
  margin: 0;
  padding: 0;
}
.DSA_text-1line{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.DSA_text-2line{
  height: 42px;
  overflow: hidden;
}
.DSA_text-3line{
  height: 54px;
  overflow: hidden;
}  
.DSA_text-4line{
  height: 72px;
  overflow: hidden;
}
.DSA_h4Header.DSA_text-2line{
  height: 32px;
}
p.DSA_text-3line{
  height: 63px;    
}
.DSA_card-widget.DSA_card-square{
  min-width: 100px;
  max-width: 100px;
  float: left;     
}
.DSA_card-widget.DSA_card-square.DSA_square-lg{
  min-width: 156px;
  max-width: 156px;
}
.DSA_card-widget ~ .DSA_card-widget{
  margin: 0 0 0 32px;
}
.DSA_card-widget.DSA_card-withLtImg .DSA_txt_left{
  padding-left: 120px;
}
.DSA_card-widget .v-menu-icon{
  background-position: center;
}
.DSA_card-ul{
  list-style: none;
  padding: 0;
  li{
    padding: 8px 0;      
  }
  li:last-child{
    padding: 8px 0 0;
  }
  li.DSA_border-top{
    border-top: 1px solid $grey-lightest !important;
  }
}  
.DSA_action-ul{
  margin: 4px 0;
  li{
    float: left;
    margin: 0 0 0 8px;
  }
}
.DSA_card-fixed{
  max-width: 156px;
  min-width: 156px;
  height: 156px;
  float: left;
  .align-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.mat-mdc-card-actions .mat-mdc-button, .mat-card-actions .mat-mdc-raised-button{
  margin: 0 !important;
}
.DSA_card-widget.DSA_card-nobg{
  background: transparent;
  box-shadow: none !important;
  padding: 0;
}
.DSA_card-widget.DSA_card-withLtImg .DSA_txt_top .DSA_txt_left{
  padding-left: 72px;
  min-height: 56px;
}    
/* blogs-tile CSS - End */

/* Datepicker CSS - Start */
/*present on top

.DSA_dateClass .mat-calendar-body-selected {
  background-color: $color-dark;
}
.DSA_dateClass .mat-calendar-header{
  background: $color-dark;
  padding: 16px 8px;
}
.DSA_dateClass .mat-calendar-controls{
  margin-top: 0;
  margin-bottom: 0;
}
.DSA_dateClass .mat-calendar-arrow{
  border-top-color: $grey-white;
}
.DSA_dateClass .mat-datepicker-content .mat-calendar-next-button, 
.DSA_dateClass .mat-datepicker-content .mat-calendar-previous-button, 
.DSA_dateClass .mat-datepicker-toggle{
  color: $grey-white;
}
.DSA_dateClass .mat-calendar-period-button{
  font-size: $heading-lg;
  color: $grey-white;
}
.DSA_dateClass .mat-calendar-table-header th{
  padding: 8px 0;
}
.mat-datepicker-content.mat-datepicker-content-touch .DSA_dateClass{
  max-width: 328px;
  max-height: 420px;
}
/* Datepicker CSS - End */
/* Timepicker CSS - Start */
.DCA_cusTime {
  .timepicker__header {
    background-color: $color-dark !important;
  }
  .timepicker-dial__control{
    font-size: $heading-lg;
    font-weight: 500;
  }

  .clock-face__clock-hand {
    background-color: $color-dark !important;

    &::after {
      background-color: $color-dark !important;
    }

    &::before {
      border-color: $color-dark !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: $color-dark !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: $color-dark !important;
  }
}
/* Timepicker CSS - End */
/* Checkbox CSS - Start */
.custom-checkbox ~ .custom-checkbox{
  margin: 0 0 0 16px;
}
/* Checkbox CSS - End */
/* Radio CSS - Start */  




.mat-mdc-radio-button ~ .mat-radio-button{
  margin: 0 0 0 16px;
}
/* Radio CSS - End */
/* Slide toggle CSS - Start */
/*present on top */
/* Input CSS - End */
/* Select CSS - Start */


$icon_select: "%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bclip-path:url(%23a);%7D.c,.d%7Bfill:%237e7e7e;%7D.c%7Bopacity:0;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='24' height='24'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b'%3E%3Crect class='c' width='24' height='24'/%3E%3Cg transform='translate(180.3 -613.698) rotate(45)'%3E%3Crect class='d' width='9' height='2' rx='1' transform='translate(317.199 562.828)'/%3E%3Crect class='d' width='2' height='9' rx='1' transform='translate(326.234 564.793) rotate(180)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
$icon_select_disabled: "%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bclip-path:url(%23a);%7D.c,.d%7Bfill:%23dadada;%7D.c%7Bopacity:0;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='24' height='24'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b'%3E%3Crect class='c' width='24' height='24'/%3E%3Cg transform='translate(180.3 -613.698) rotate(45)'%3E%3Crect class='d' width='9' height='2' rx='1' transform='translate(317.199 562.828)'/%3E%3Crect class='d' width='2' height='9' rx='1' transform='translate(326.234 564.793) rotate(180)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";
  
/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.DSA_custom-select .mat-mdc-option .mat-option-text{
  font-size: $text-md;
  color: $grey-dark;
}
.DSA_custom-select .mat-mdc-option.mat-active{
  background: $grey-lightest;
}
.mat-mdc-select-panel.DSA_custom-select .mat-mdc-optgroup-label, .mat-select-panel.DSA_custom-select .mat-mdc-option{
  height: 40px;
  line-height: 40px;
}

.mat-primary.DSA_custom-select .mat-pseudo-checkbox-checked, .mat-primary.DSA_custom-select .mat-pseudo-checkbox-indeterminate{
  background-color: $color-dark;
}
/* Select CSS - End */

/*custom tag select*/
.custom-chip-list{
width:100%;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.custom-chip-list.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #DADADA
}

.custom-chip-list .mat-mdc-chip.mat-mdc-standard-chip.DSA_chipStyle-Normal, .custom-chip-list .mat-mdc-chip.mat-mdc-standard-chip.DSA_chipStyle-Big {
background-color: #FFFFFF;
height: 24px;
padding: 0px 8px;
line-height: 24px;
font-size: 0.75rem;
border-radius: 20px;
display: inline-block;
border: 1px solid #7E7E7E;
color: #000;
min-height: 24px;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.custom-chip-list.mat-mdc-form-field.mat-focused .mat-form-field-ripple{
background-color: $color-dark;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.custom-chip-list.mat-mdc-form-field.mat-focused .mat-form-field-label{
color: $grey-light;
}
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.custom-chip-list.mat-form-field-appearance-legacy .mat-form-field-ripple {
height: 1px;
}
 /*custom tag select*/

/*tab component styling*/


/* present on top */



/*Atom-Text*/
.DSA_h1Header{
  font-size:$heading-lg;
  font-weight: $text-medium;
}
.DSA_h2Header{
  font-size:$heading-md;
  font-weight: $text-medium;
}
.DSA_h3Header{
  font-size:$heading-sm;
  font-weight: $text-medium;
}
.DSA_h4Header{
  font-size:$text-lg;
  font-weight: $text-medium;
}
.DSA_mainBodyTxt{
  font-size:$text-lg;
  font-weight: $text-regular;
}
.DSA_mainBodyTxtMedium{
  font-size:$text-lg;
  font-weight: $text-medium;

}
.DSA_mainBodyTxt2,.DSA_mainBtn_normal, .DSA_h5Header{
  font-size:$text-md;
  font-weight: $text-medium;
}
.DSA_mainBodyTxt3{
  font-size:$text-md;
  font-weight: $text-regular;

}
.DSA_mainBodyTxt4{
  font-size:$text-sm;
  font-weight: $text-regular;   
}
.DSA_mainBtn_small{
  font-size:$text-sm;
  font-weight: $text-medium;
}
.DSA_infoTxt{
  font-size:$text-xs;
  font-weight: $text-regular;
}

.DSA_linkStyle1{
  font-size:$text-md;
  font-weight: $text-medium;
  color:$yellow-color;
}
.DSA_linkStyle1:hover{
  color:$yellow-color;
}
.DSA_linkStyle2{
  font-size:$text-md;
  font-weight: $text-regular;
  color:$color-dark;
}
.DSA_linkStyle2:hover, .DSA_linkStyle2:focus{
color: $color-dark;
}
/* Forms - Feedback buttons */
.DSA_feedbackIcons_ul{
list-style: none;
li{
  margin: 0 32px 0 0;
  float: left;
  a{
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
  }    
}  
}
/* Forms - Icon Radio */
.DSA_iconRadio_ul{
list-style: none;
li{
  margin: 0 32px 0 0;
  float: left;
  position: relative;
  a{
    @extend %default-shadow;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
  }
  a:hover{
    text-decoration: none;
  }    
  a::after{
    font-weight: 400;
    font-size: $text-xs;
    color: $grey-dark;
    position: absolute;
    bottom:-12px;
    width:100%;
    text-align: center;
    line-height: 14px;
 }   
}
li.active{   
  a::after{
    color: $color-dark;
  }
}
li.disabled{
  a{
    cursor: default;
  }    
  a::after{
    color: $grey-lighter;
  }
}
}

/*atom-chip*/
.DSA_chipStyle{
line-height: 16px;
height:16px;
padding:0px 8px;  
font-size: $text-xs;
border-radius:12px;
display: inline-block;
text-transform: uppercase;
}
.mat-mdc-chip.mat-mdc-standard-chip.DSA_chipStyle-Normal,
.mat-mdc-chip.mat-mdc-standard-chip.DSA_chipStyle-Big{
background-color:$grey-white;
height:24px;
padding:0px 8px;
line-height: 24px;
font-size: $text-sm;
border-radius:20px;
display: inline-block;
border:1px solid $grey-light;
color:$black;
min-height:24px;
}
.mat-mdc-chip.mat-mdc-standard-chip.DSA_chipStyle-Big{
height:32px;
line-height: 32px;
min-height:32px;
}

.mat-mdc-chip.mat-mdc-standard-chip.DSA_chipStyle-Normal.active,
.mat-mdc-chip.mat-mdc-standard-chip.DSA_chipStyle-Big.active{
background-color:$color-lightest;
border:1px solid $color-thick;
color:$color-thick;
}

a.DSA_chipStyle{
text-decoration: none;
}
.DSA_closedchip{
border:1px solid $green-color;
color:$green-color;
}
.DSA_inprogresschip{
border:1px solid $yellow-color;
color:$yellow-color;
}
.DSA_greychip{
border:1px solid $grey-light;
color:$grey-light;
}
.DSA_openchip{
border:1px solid $red-color;
color:$red-color;
}
.DSA_finalchip{
border:1px solid $blue-color;
color:$blue-color;
}
.DSA_whitechip{
border:1px solid #fff;
color:#fff;
}
.DSA_darkBG{
background:#333;
padding:10px;
color:$white-color;
}
.DSA_selectionIcon{
line-height: 1px;
}

.DSA_chipStyle-Big.DSA_choiseChip.active{
img, i.icon{
  display: none;
}
i.icon-process-complete{
  display: block;
}
}


/*list items*/

.DSA_listItemSingle {
list-style: none;
padding:0px;
margin:0;
}
.DSA_listItemSingle li{
padding:0px;
margin:0 0 8px 0;
-webkit-transform-style: preserve-3d;
-moz-transform-style: preserve-3d;
transform-style: preserve-3d; 
position: relative;
}
.DSA_listItemSingle li:last-child{
margin: 0;
}
.DSA_listItemSingle li>span,.DSA_listItemSingle li>a{
padding: 8px 16px;
display: block;
position: relative;
}
.DSA_listItemSingle li>a:hover{
text-decoration: none;
color:inherit;
}

.DSA_listItemSingle li.active>span,.DSA_listItemSingle li.active>a{
background-color: $grey-lightestBg;

}
.DSA_listItemSingle li.active{
background-color: $grey-lightest !important;
}

.DSA_blockLabel{
display: block;
line-height: 8px;
margin: 4px 0px 2px 0px;
}
.DSA_marginicon40{
margin: 4px 0 0 0;
display: block;
}
.DSA_vertical-RightCenter{
@include verticalCenter;
right:16px;
}
.DSA_vertical-LeftCenter{
@include verticalCenter;
left:16px;
}
.DSA_vertical-RightCenter0{
@include verticalCenter;
right:0px;
}
.DSA_vertical-LeftCenter0{
@include verticalCenter;
left:0px;
}
.DSA_vertical-LeftCenter32{
@include verticalCenter;
left:32px;
}
.DSA_textSingle{
line-height: 40px;  
}
.DSA_textDouble{
line-height: 20px;
}
.DSA_icontext{
line-height: 10px;
}
.DSA_listLeft{
width:65%;
float: left;
padding-right:8px;
position: relative;
}
.DSA_listRight{
width:35%;
float: right;
text-align: right;
padding-left:8px;
}
.DSA_listBorder{
border-bottom: 1px solid $grey-light;  
}
.DSA_supTxt{
vertical-align: top;
}  
.DSA_chat-dot {
display: inline-block;
width: 10px;
height: 10px;
border-radius: 50%;
vertical-align: top;
margin-left: -10px;
}  
.DSA_vTop{
vertical-align: top;
} 
.DSA_inline-list li{
display: inline-block;
margin-right: 32px;
vertical-align: middle;
}
.DSA_list-1line{
line-height: 24px;
}
/*message bar*/

.DSA_alert {
position: relative;
margin-bottom: 1rem;
border: 1px solid transparent;
border-radius: 4px;
padding: 16px 50px 16px 16px;
@extend %active-shadow;
}
.DSA_alert-success {
color: $white;
background-color: $green-color;
border-color: $green-color;  
}

.DSA_alert-danger {
color: $white;
background-color: $red-color;
border-color: $red-color;  
}
.DSA_alert-warning {
color: $white;
background-color: $yellow-color;
border-color: $yellow-color;  
}
.DSA_alert-info {
color: $white;
background-color: $black;
border-color: $black;  
}
.DSA_alert-black {
color: $white;
background-color: $black;
border-color: $black;  
}

.DSA_mutil-selector-wrap{
display: flex;
 justify-content: space-between;

}

.DSA_mutil-selector-wrap>div{
width: 50%;

}
.DSA_mutil-selector-wrap>div.wdth30{
width: 30%;

}
.DSA_mutil-selector-wrap>div.wdth70{
width: 70%;

}

.DSA_email-with-domain .custom-form-field{
width: 64%;
}

.DSA_email-with-domain:after{

color: #e7e7e7;
font-size: 16px;
margin-left: 16px;
content: "@infosys.com";
}
.DSA_header_bg{
background: $color-dark;
@extend %default-shadow;
}

.custom-tab .mdc-tab--active .DSA_tabIcon-Circle{
background: $grey-white;
}
.DSA_tab-bar-icon .mdc-tab-indicator{
display: none;
}
.DSA_tab-bar-icon .mdc-tab{
.icon.inprogress-icon{    
  position: absolute;
  left: -12%;
  top: 18px;
  width: 24px;
  height: 24px;
}
}

/*chip css*/
.DSA_chipIconRight{
margin-right: -5px !important;
margin-left: 5px !important;
float: right;
margin-top: 4px !important;
}
.DSA_chipIconLeft{
margin-left: 0 !important;
margin-right: 5px !important;
float: left;
margin-top: 4px !important;
}

.DSA_chipIconRightBig{
margin-right: -3px !important;
margin-left: 5px !important;
float: right;
margin-top: 3px !important;
}
.DSA_chipIconLeftBig{
margin-left: -3px !important;
margin-right: 5px !important;
float: left;
margin-top:3px !important;
}
.DSA_choiseChip{
cursor: pointer;
}


/*bottom sheet customisation*/
.mat-bottom-sheet-container,
.mat-bottom-sheet-container-large,
.mat-bottom-sheet-container-medium,
.mat-bottom-sheet-container-xlarge{
border-top-left-radius: 16px !important;
border-top-right-radius: 16px !important;
overflow: hidden !important;
}


.DSA_scrollcontainer {
  position: relative;
  height: 300px;
}


/*accordion customization*/




.cus-accordion-style2 .mat-expansion-panel-header{
height:29px !important;
padding:0 4px 0 0;
font-size: $text-lg;
font-weight: $text-medium;
border-radius:0;  
}
.cus-accordion-style2 .mat-expansion-panel-header-title{
color:$black;
}
.cus-accordion-style2 .mat-expansion-indicator::after,
.cus-accordion-style2 .mat-expansion-panel-header-description{
color:$grey-light;  
}
.cus-accordion-style2 .mat-expansion-panel.mat-expanded{
background-color: transparent; 
}
.cus-accordion-style2 .mat-expanded .mat-expansion-panel-body{
background-color: transparent; 
}
.cus-accordion-style2 .mat-expanded .mat-expansion-panel-header-title,
.cus-accordion-style2 .mat-expanded .mat-expansion-indicator::after,
.cus-accordion-style2 .mat-expanded .mat-expansion-panel-header-description{
color:$black;
}
.cus-accordion-style2 .mat-expansion-panel {
margin: 0 0 16px!important;
border-bottom: 1px solid $grey-light;
background: transparent;
}
.cus-accordion-style2 .mat-expansion-panel:not([class*=mat-elevation-z]) {
box-shadow: none;
}
.cus-accordion-style2 .mat-expanded.mat-expansion-panel:not([class*=mat-elevation-z]) {
box-shadow: none;
}
.cus-accordion-style2 .mat-expansion-panel-body{
padding: 8px 0 0;
}
.cus-accordion-style2.mat-accordion .mat-expansion-panel:first-of-type,
.cus-accordion-style2.mat-accordion .mat-expansion-panel:last-of-type{
border-radius: 0;
}
.cus-accordion-style2 .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-keyboard-focused, 
.cus-accordion-style2 .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-program-focused, 
.cus-accordion-style2 .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover{
background: transparent;
}
.cus-accordion-style2 .mat-expansion-panel-content{
font-size: 16px;
}


/*expand collapse card*/
.DSA_card-widget.mat-mdc-card > .collapseAction.mat-mdc-card-actions:last-child{
margin-bottom: -16px;
padding:0;
}

.DSA_showmore{
height:auto;
overflow: auto;
}
.DSA_showless{
height:120px;
overflow: hidden;
}

/*hamburgermenu*/
.DSA_hamburgerMenuCtr{
height:100vh;
width:100vw;
position: fixed;
top:0;
left:0;
z-index: 2147483647;
background: rgba(0,0,0,0.5);
transform: translateX(-100%);
transition: transform 0.4s;
}
.DSA_hamburgerMenuCtr.active{
left:0;
transform: translateX(0);
}
.DSA_hamburgerMenuInner{
height:100vh;
width:300px;
background: $grey-lightwhite;
position: relative;
}
.DSA_purplecard{
min-width: 100%;
background-color: $color-dark;
box-shadow:none !important;
color:$white;
border-radius: 0 !important;
}
.DSA_bottomUtil{
position: absolute;
right:0;
bottom:0;
width: 100%;
}


.DSA_rightMenuCtr{
height:100vh;
width:100vw;
position: fixed;
top:0;
right:0;
z-index: 2147483647;
background: rgba(0,0,0,0.5);
transform: translateX(100%);
transition: transform 0.4s;
}
.DSA_rightMenuCtr.active{
right:0;
transform: translateX(0);
}
.DSA_rightMenuInner{
height:100vh;
width:300px;
background: $grey-lightwhite;
position: relative;
float:right;
}
.DSA_filterClose{
margin-top:-5px;
}

.DSA_card-widget .v-menu-icon.bottom{
top: auto;
bottom:16px;
right: 8px;
position: absolute;
}

.DSA_status{
width: 8px;
height: 8px;
border-radius: 50%;
display: inline-block;
margin-left: 8px;
}

.DSA_content-Active{
display: block;
}

/*timeline css*/

.DSA_timelineUL li{
padding-bottom:8px;
background: url('../assets/images/timelineGreydotted.png') repeat-y 7px 0px;
}

.DSA_timelineUL li.greenline{
background-image: url('../assets/images/timelineGreen.png');
}
.DSA_timelineUL li.blueLine{
background-image: url('../assets/images/timelineBlue.png');
}
.DSA_timelineUL li.orangeline{
background-image: url('../assets/images/timelineOrange.png');
}
.DSA_timelineUL li.redline{
background-image: url('../assets/images/timelineRed.png');
}


.DSA_timelineUL li i.hideBg{
background-color: #fff;
border-radius:50%;
}
.DSA_timelineUL li:first-child{
background-position: 7px 16px;
background-repeat: no-repeat;
}
.DSA_timelineUL li:last-child{
background:none;
background-repeat: no-repeat;
}

// .DSA_timelineDate li{
// padding-bottom:8px;
// background: url('assets/images/timelinePurpledotted.png') repeat-y 82px 0px;
// position: relative;
// }
.DSA_timelineDate li:first-child{
background-position: 82px 0px;
}
// .DSA_timelineDate li:last-child{
// background: url('assets/images/timelinePurpledotted.png') repeat-y 82px 0px;
// }
.DSA_timelineDate li .DSA_timelineContent {
margin-left: 0px;
}
// .DSA_timelineDate li:after{
// background: url('assets/images/timelineRing.png') repeat-y;
// width:8px;
// height:8px;
// display: inline-block;
// content:'';
// position: absolute;
// left:79px;
// top:2px;
// }
.DSA_timelineDate li .leftTimelineText{
width:70px;
text-align: right;
float: left;

}
.DSA_timelineDate li .rightTimelineText{
margin-left: 100px;
}

.DSA_timelineContent{
margin-left: 32px;
}

.DSA_timelineDateCtr{
padding-top:32px;
}
// .DSA_timelineDate li.DSA_timelineDateCtr:first-child{
// background: url('assets/images/timelinePurpledotted.png') no-repeat 82px 40px;
// }
.DSA_timelineDateCtr h3{
margin-top:-32px;
margin-bottom: 16px;
margin-left: 100px;
}

.DSA_timelineDate li.DSA_timelineDateCtr:after{
top:38px;
}
/*timeline css*/

/*modal customization*/


.DSA_noheader.custom_modal .modal-body{
padding:16px 16px 16px 16px;
}

.custom_modal .modal-footer{
padding:8px 16px;
border-top: 1px solid $grey-lightest;
justify-content: flex-start;
}
@media screen and (max-width:575px){
.custom_modal .modal-dialog{
  margin:16px;    
}
}

/*modal customization*/

.DSA_card-widget hr.whitedivider{
border:0;
border-top:1px solid $grey-white;
margin:0;
opacity: .25;
}
.DSA_profileList.DSA_listItemSingle li > span{
background:none;
padding: 8px 0;
}
.DSA_profileList{
text-align: left;

}
.DSA_typeaheadResult li{
padding: 8px 16px;
color: $grey-light;
cursor: pointer;
}

.DSA_typeaheadResult li span{
color:$color-thick;

}

.DSA_backdrop {
position: fixed;
top: 0;
left: 0;
z-index: 1040;
width: 100vw;
height: 100%;
background-color: #000;
opacity: 0.5;
}
.owl-carousel .owl-item img.fixedImg{
width: auto;
display: inline-block;
}
.DSA_main-content{
margin: 16px 0;
}
.DSA_icon-bg{
background: $color-lighter;
border-radius: 50%;
display: inline-block;
vertical-align: middle;
}
.DSA_btn-cont button ~ button{
margin: 0 0 0 16px;
}

.DSA_circle-img{
width: 100%;
height: 100%;
border-radius: 50%;
display: inline-block;
}
.DSA_circle-img.blue{
background: $blue-color;
}
.DSA_circle-img.green{
background: $green-color;
}
.DSA_circle-img.red{
background: $red-color;
}
.DSA_link-iconTxt{
display: inline-block;
line-height: 22px;
}
.DSA_img_ul{
width: 80px;
height: 40px;
position: relative;
margin: 0 auto;
}
.DSA_img_ul li{
position: absolute;
top:0;
left: 0;
}
.DSA_img_ul li.li_01{
z-index: 0;
}
.DSA_img_ul li.li_02{
z-index: 1;
left: 10px;
}
.DSA_img_ul li.li_03{
z-index: 2;
left: 20px;
}
.DSA_img_ul li.li_04{
z-index: 3;
left: 30px;
}
.DSA_img_ul li.li_05{
z-index: 4;
left: 40px;
}

.DSA_stickyHeader{
box-shadow: 0px 5px 5px -2px rgba(0,0,0,0.3);
}
.DSA_commentTxtCtr{
margin-left:56px;
}

.DSA_commentTextareaCtr{
background: $grey-lightwhite;
position: relative;
}
.DSA_commentTextarea{
margin-right: 30px;
padding:8px 16px;

}
.DSA_commentTextarea textarea{
width:100%;
height:50px;
border:0;
background: none;
}

.DSA_btn-fixed{
position: fixed;
width: 100%;
bottom: 0;
left: 0;
z-index: 1;
}
.DSA_contentArea{
margin: 0 0 66px;
}
.DSA_dropdown-iconOnly::after{
display: none !important;
}
.dropdown-item.active, .dropdown-item:active{
background-color: transparent;
}


.sp2_labelField {display:inline-block;width:auto;margin-right:10px;}

.sp2_newInfyTheme .sp2_labelField .form-control.sp2_dateRanger {
  border-radius: 0;
  box-shadow: none;
  text-align: left;
  padding-left: 0;
  background-image: url("/assets/images/calendar-icon.png");
  background-repeat: no-repeat;
  display: inline-block;
  background-position: right center;
  margin: 6px 0;   
  /* added for bus pass */
}


.sp2_wall_tab .sp2_formBlock {
 margin-right: 0;
 min-width: 135px
}

.searchResultpage .sp2_formBlock{ min-width: auto;margin-right: 0}

.sp2_formBlock {
min-width: 150px;
display: inline-block;
margin-right: 68px;
vertical-align: top;
position: relative;
left: 36px;
margin-top: 26px;
width: 1px;

}

.sp2_formBlock2{
min-width: 300px !important;
}
.sp2_form-label {
text-transform: none;
}

.sp2_approverEnrollLabel {margin:10px 0;}

.enrollLabel{
top: 290px;
left: 873px;
width: 130px;
height: 43px;
text-align: left;
font-size: 15px;
font-family: "Roboto";
letter-spacing: 0.16px;
color: #231F20;
opacity: 1;
}
.sp2_discalimer-sm {
font-style: italic;
font-size: 11px;
margin-bottom: 10px;
color: #a4a4a4;
}
.sp2_customCombo .sp_btnStye2.btn-primary {
padding: 5px 20px;
margin-top: 15px;
font-size: 11px;
}

.sp2_customCombo {
text-align: center;
}

.sp2_customCombo .select2-container--default .select2-selection--single .select2-selection__rendered {
text-align: center;
border: none;
border: 1px solid #ef6b52;
padding: 6px 15px;
border-radius: 15px;
text-transform: uppercase;
background-color: #fff;
color: #ef6b52;	
font-weight: bold;
font-size: 12px;
height: 30px;
}

.sp2_newInfyTheme .sp2_customCombo .select2-container--default .select2-selection--single .select2-selection__rendered {
border: none;
border-radius: 0;
border-bottom: 1px solid #ccc;
color: #8626c3;
text-align: left;
padding-left: 0;
min-width: 150px;
padding-right: 45px;
}
.sp2_customCombo .select2-container {
min-width: 115px;
}

/* Hide the browser's default checkbox */
.container input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
 height: 0;
   width: 0;
   
}
.csCheckbox{
 text-align: justify;
 padding-left:20px !important;
}
/* Create a custom checkbox */
.checkmark {
position: absolute;
/* top: 0; */
/* left: 461px; */
/* margin-left: -232px;  */
height: 17px;
width: 18px;
background-color: white;
border: 0.2px solid #8626c3;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
background-color: white;
}

/* When the checkbox is checked, add a  background */
.container input:checked ~ .checkmark {
/* background-color: #2196F3; */
background: url("/assets/images/check-on-purple.png") no-repeat;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
display: block;
}
.tapItRemote{
color:#666666;
}

.tapItRemote:hover{
color:#8626c3;
}
 
.sp2_formField {display:inline-block;width:auto;margin-right:10px;}

.sp2_approverLabel {margin:10px 0;}
.sp2_approverLabel input {border:none; border-bottom:1px solid #333;padding-bottom:5px;display:inline-block;margin:0 5px;}

.form-input{
font-size: 14px;
/* font-weight: bold; */
color: #212121;
}
.marginT15 {
margin-top: 15px !important;
} 
.sp2_radio-label {
 min-width: 300px;
 font-size: 12px;
 font-weight: normal;
 color: #898989;
 display: block;
 padding-left: 0;
 text-align: left;
 text-transform: uppercase;
 margin-bottom: 0;
 font-family: "RobotoMedium";
}
.clsSelect{
margin: 7px 0;
}
.select-selected {
background-color: transparent;
border-bottom: 1px solid #ccc;
color: #212121;
width: 150px !important;
/* font-weight: bold; */
outline: none;
}
.select-selected option{
color: black !important;
padding: 8px 16px;
border: 1px solid transparent;
border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
cursor: pointer;
/* color: #8626c3; */
}
.sp2_radioBlock {
 min-width: 300px;
 display: inline-block;
 margin-right: 25px;
 vertical-align: top;
 position: relative;
 text-align: left;
}

.sp2_date{
min-width:300px !important;
margin-top:28px;
left:36px;
}



.leftmargin{
left: -18px;
}
.dependent{
margin-left: 1063px;
font-size: 15px;
font-weight: 500;
margin-bottom: -18px;
}



.sp2_radioField {display:inline-block;width:auto;margin-right:40px;}

.sp2_newInfyTheme .sp2_radioField .form-control.sp2_dateRanger {
border-radius: 0;
box-shadow: none;
text-align: left;
padding-left: 0;
background-image: url("/assets/images/calendar-icon.png");
background-repeat: no-repeat;
display: inline-block;
background-position: right center;
margin: 6px 0;   
/* added for bus pass */
}

.sp2_reasonLeaveTxt textarea {color:#898989;border:1px solid #898989;border-radius:4px;padding:10px;width:40%;margin:20px 0}


@media screen and (max-width: 980px) {
.sp2_reasonLeaveTxt textarea {
 width: 90%;
}

}