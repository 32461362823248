/*progress bar*/
.DSA_customprogress .mat-mdc-progress-bar{
    height: 9px !important;
    border-radius: 5px;
}
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.DSA_customprogress .mat-progress-bar-background{
    fill:$bg-neutral-50;
}
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.DSA_customprogress .mat-progress-bar-buffer{
    background-color:$bg-neutral-50;
}
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.DSA_customprogress .mat-progress-bar-fill::after{
    background-color:$bg-primary-80;
    border-radius: 5px;
}
/*progress bar*/