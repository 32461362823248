.DSA_Wb_form-sm-cont{
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-legacy .mat-form-field-wrapper{
      padding: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-subscript-wrapper, .mat-form-field-label-wrapper{
      display: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-legacy .mat-form-field-underline{
      bottom: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-legacy .mat-form-field-infix{
      padding: 0;
      border: none;
    } 
}