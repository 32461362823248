/* You can add global styles to this file, and also import other style files */

// @import './custom-theme';
// h1 {
//     color: blue;
// }
// html, body { height: 100%;  }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import "~bootstrap/dist/css/bootstrap.css";
// @import url('https://fonts.googleapis.com/css2?family=Myriad+Pro:wght@400;600&display=swap');

.mat-grid-tile {
  display: block;
  position: absolute;
  overflow: hidden;
  width: 23% !important;

}

/*custom Sass*/
@import "../saas/variables";
@import "../saas/typography";

@import "../saas/mixins";
@import "../saas/media_query";

/*plugin css*/
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import '~perfect-scrollbar/css/perfect-scrollbar.css';
//@import "../saas/my-theme.scss";

@import "../saas/main.scss";

/*custom css*/
@import "../saas/customBootstrap.scss";
@import "../saas/allComponents.scss";
@import "../saas/web_allComponents.scss";
@import "../saas/web_iconStyles.scss";
@import "../saas/iconStyles.scss";
@import "../saas/utility.scss";
@import "../saas/app-style.scss";
@import "../saas/appcustom.scss";
@import "../assets/fonts/DS-iconfont.css";
// @import "@material/tab-bar/dist/mdc.tab-bar.css";
// @import "@material/tab-scroller/dist/mdc.tab-scroller.css";
// @import "@material/tab-indicator/dist/mdc.tab-indicator.css";
// @import "@material/tab/dist/mdc.tab.css";
 
@media screen and (max-width: 750px){
  .cdk-overlay-pane{
    max-width: 100% !important;
  }
}

.add_attach {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: white;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16) !important;
  margin-right: 5%;  
  margin-top: 15%;
}

.mat__cell_td{padding: 7%;}
.mat_form {width: 75%;}
.add_button {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: white;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16) !important;
  margin-right: 5%;  
}
.tr_bottom {border-bottom: 1px solid #d8d8d8;}
.mt3{margin-top: 3%;}
.education_error {position: absolute !important; font-size: 11px !important; top: 33px !important;}
.search {
    background: url('../assets/images/icons/default_icons/icon_search.png') no-repeat;
    display: block;
    background-repeat: no-repeat !important;
    margin: 0% 0% 0% 0%;
    background-position: 100% 0;
}
.edit{width: 15%;margin-left: 75%;}
.ds_h5Inner {
  color: #000!important;
  margin: 24px 0px 4px 0px;
  font-size: 16px;
  font-weight: bold;
}
.mat-mdc-table {
  background: #fff ;
}
table {
  width: 100%;
}
.th-header {
  background: #d8d8d8;
  font-size: 16px;
  color: #000;
}
.mtb10{margin-bottom: 5% !important;margin-top: 5% !important}
td.mat-mdc-cell, td.mat-mdc-footer-cell, th.mat-mdc-header-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: none !important;
}
.mat-mdc-cell, .mat-mdc-footer-cell {
  color: rgba(0,0,0,.87);
  // width: 14% !important;
}

.mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
  stroke: #8626C3 !important;
}