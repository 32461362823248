/*material tab*/
.DSA_navTab{
    padding:8px 0px;
}
.DSA_navTabWhite .mat-mdc-tab-header{
    background:$bg-white;
}
.DSA_navTabTrans .mat-mdc-tab-header{
    background:none;
}
.DSA_navTabTransBorder .mat-mdc-tab-header{
    background:none;
    border-bottom:1px solid $bg-neutral-50;
}    
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-label,
.DSA_wb_customTab .mat-mdc-tab-link{
    height: 80px;
    color:$text-neutral-80;
    font-size: $size-button-18;
    font-weight: $font-400;
    font-style: normal;
    min-width:auto;
    opacity: 1;
    padding: 0 24px;
    margin:0 0px;
}
.DSA_wb_customTab .mat-mdc-tab-header, 
.DSA_wb_customTab .mat-mdc-tab-nav-bar{
    border-bottom: 1px solid $border-neutral-40;
    
}      
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-label:hover,
.DSA_wb_customTab .mat-mdc-tab-link:hover{
    color:$text-black;
    background: $bg-neutral-50;
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-label:active,
.DSA_wb_customTab .mat-mdc-tab-link:active{
    color:$text-black;
    background: $text-neutral-60;
}
.DSA_wb_customTab .cdk-focused:active{
    color:$text-primary-80;
    font-weight: $font-400;
    background: $text-neutral-60;
} 
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-label-active{
    color:$text-primary-80;
    font-weight: $font-600;
    font-style: normal;    
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-label-active:hover{
    background:none;
    color:$text-primary-80;
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-label-active:active{
    font-weight: $font-600;      
    color:$text-primary-80;
    background: $text-neutral-60;
}    
.DSA_wb_customTab .mat-mdc-tab-group.mat-primary .mat-mdc-tab .mdc-tab-indicator__content--underline,
.DSA_wb_customTab .mat-mdc-tab-nav-bar.mat-primary .mat-mdc-tab .mdc-tab-indicator__content--underline{
    background: $bg-primary-80;
    height:4px;
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination{
    height: 24px;
    width: 24px;
    min-width:24px;
    margin-top: 30px;
    padding: 0;
    background: $bg-white;
    @extend %default-shadow;
  }
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination-before{
    margin-left: 5px;  
}
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.DSA_wb_customTab .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination-after{
    margin-right: 5px;
}
.noTAB_border .mat-mdc-tab-header, .noTAB_border .mat-mdc-tab-nav-bar{
    border:0;
}