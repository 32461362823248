/* Custom select for Web - Start */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-panel-wrap{
  margin-top: 30px;
  }
  
  .mat-mdc-select-panel{
  border-radius: $border-radius-big;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .DSA_Wb_custom-form-field .mat-select-value{
      color: $text-black;
      padding-top: 10px;
    }
    
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .DSA_Wb_custom-form-field .mat-select-arrow{
      border: none;
      width:24px;
      height: 24px;
      margin: -2px 0 0;
      font-family: "DS-iconfont", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    line-height: 1;
    font-size:24px;
    display: block;
    background-repeat: no-repeat!important;
    margin: auto;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .DSA_Wb_custom-form-field .mat-select-arrow:after{      
      display: inline-block;
      background-repeat: no-repeat!important;
      margin: auto;
      color: $icon-neutral-80;  
      content: "\e016";
      width:24px;
      height:24px;
  
    }
    .DSA_wb-custom-select{
       
        color: $text-black;
    height:53px;
    background: $bggrey-lightwhite;
    border-radius: $border-radius-big $border-radius-big 0 0;
    padding: 14px 12px 10px;
    transition: 0.2s all;
      &:hover:not([disabled]){
    background: $bg-white;
    @extend %input-hover-shadow;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline{
    background: $bg-neutral-80!important;
  }
    }
      }
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .DSA_wb-custom-select-panel .mat-mdc-option .mat-option-text{  
      color: $text-black; /*added to replace dark grey*/
      font-size: $size-text-14;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
    .mat-select-panel.DSA_wb-custom-select-panel .mat-optgroup-label, .mat-select-panel.DSA_wb-custom-select-panel .mat-option,.mat-autocomplete-panel .mat-option{
      height: 32px;
      line-height: 32px;
    }    
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .DSA_wb-custom-select-panel .mat-mdc-option:focus:not(.mat-option-disabled){
      background: $text-neutral-60;
    } 
    /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
    .DSA_wb-custom-select-panel .mat-option:hover:not(.mat-option-disabled):not(.mat-active),.mat-autocomplete-panel .mat-option:hover:not(.mat-option-disabled):not(.mat-active){
      background: $bg-neutral-40;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
    .DSA_wb-custom-select-panel .mat-option.mat-active,
    .DSA_wb-custom-select-panel .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),.mat-autocomplete-panel .mat-option.mat-active{
      background:$bg-primary-50;
    }
    
    .mat-primary.DSA_wb-custom-select-panel .mat-pseudo-checkbox-checked, .mat-primary.DSA_wb-custom-select-panel .mat-pseudo-checkbox-indeterminate{
      background-color: $bg-primary-80;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .DSA_Wb_custom-form-field .mat-mdc-icon-button .mat-button-focus-overlay{
      background: transparent;
    }
    .DSA_Wb_custom-form-field.mat-form-field-disabled .mat-mdc-icon-button{
      opacity: 0.5;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
    .DSA_wb-custom-select-panel.DSA_wb-custom-select-panel-withBg .mat-mdc-option .mat-option-text{
      font-size: $size-text-14;
    }
    .DSA_wb-custom-select-panel .mat-pseudo-checkbox{
      border-radius: $border-radius-small;
      color: $text-neutral-80;
    }
    
    /* Select without label - Start */
    .DSA_Wb_custom-form-field.custom-select-withBg{
      width: auto;  
      background: $bg-white;
      padding: 4px 8px;
      border-radius: $border-radius-small;
      line-height: $line-height-16;
      &:hover{
         background: $bg-neutral-40;
         border-radius: $border-radius-small;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-wrapper{
        padding: 0;    
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix{
        padding: 0;
        border: none;
        width: auto;
      }
      .mat-mdc-select{
        width: auto;
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        .mat-select-value{
          width: auto;
          max-width: 120px;
          padding: 0 8px 0 0;
          line-height: 24px;
          font-size: $size-text-14;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-underline, .mat-form-field-subscript-wrapper, .mat-form-field-label-wrapper{
        display: none;
      }
      .label-inline{
        margin: 0 8px 0 0;
        color: $text-neutral-80;
        font-size: $size-text-14;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-arrow{
        margin: 0;
      }
    }
    .DSA_Wb_custom-form-field.custom-form-pagination{
      width: auto;
      padding: 0;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-wrapper{
        padding: 0;    
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix{
        padding: 0;
        border: none;
        width: auto;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-underline, .mat-form-field-subscript-wrapper, .mat-form-field-label-wrapper{
        display: none;
      }
      .label-inline{
        margin: 0 8px 0 0;
        color: $text-neutral-80;
        font-size: $size-text-14;
        line-height: 32px;
        float: left;
      }
      input.pagination_input{
        height:32px;
        width: 32px;
        font-size: $size-text-14;
        margin: 0;
        background: $bg-white;
        border-radius: $border-radius-small;
        @extend %mb-flat-shadow;
        text-align: center;
        padding: 0;
      }
    }
    .DSA_Wb_custom-form-field.custom-select-withBg.fullWdth{
      width: 100%;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix{
        width: 100% !important;
      }
      .mat-mdc-select{
        width: 100%;
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
        .mat-select-value{
          width: 100%;
        }
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
    .mat-autocomplete-panel{
    
    border-radius: $border-radius-big;
    }
    
  
    /* .mat-chip-list{  
      .mat-chip.mat-standard-chip{    
        margin-top: 10px;
      }  
    } */
  
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .DSA_wb_custom-chip-list .mat-form-field-label{
    
    padding: 0px!important;
    }
  
    .DSA_selectwithInfoCtr{
   /*  .DSA_Wb_custom-form-field{
    width: 98%!important;
    } */
    .DSA_selectwithInfo{
      margin-right: 32px;
  }
    }
  
    .DSA_wb_editable_form-field{
    /*input{
    padding: 8px 12px 10px!important;
    }
    .mat-select-value{
    padding-top: 0;
    }*/
    /*added new  */   
      input.mat-mdc-input-element {
      height:24px;
      background: none;
      border-radius: $border-radius-none;
      margin:0;
      margin-top: -.0625em;
      padding:0;
      }    
      input.mat-mdc-input-element:hover:not([disabled]){
          background: none;
          box-shadow:none;
      }    
      &:hover:not([disabled]),
      input.mat-mdc-input-element:hover:not([disabled]),
      textarea.mat-input-element:hover:not([disabled]){
          box-shadow:none !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-value{
          padding-top: 5px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-arrow{
        margin: 0;
      }
  /*added new  */ 
    }
  
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .DSA_wb_custom-chip-list .mat-chip-list-wrapper{    
      border-radius: $border-radius-big $border-radius-big 0 0;
      margin: 0!important;
      padding:0 8px;
  transition: 0.2s all;
      &:hover{
        background: $bg-white;
        @extend %input-hover-shadow;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
      .mat-mdc-standard-chip, .mat-chip-list-wrapper input.mat-input-element{
      
      background: none!important;
      }
      input.mat-mdc-input-element{
  
      padding: 14px 8px;
        &:hover:not([disabled]){
  
  background: none!important;
  }
      }
  
  }
  
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-should-float.DSA_wb_custom-chip-list{
   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
   .mat-form-field-label{
   
  padding: 0 16px 8px!important;
   }
  }
  .DSA_Wb_custom-form-field.DSA_form_nofloatLabel{
    .DSA_wb-custom-select{
      height: 46px;
      padding: 8px;
      position:absolute;
      top:9px;
      border-radius: $border-radius-small $border-radius-small 0 0;
      &:hover:not([disabled]){
        background: $bg-primary-50;
        box-shadow: none !important;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-value{
      padding: 0;
    }
    &.mat-form-field-invalid .DSA_wb-custom-select{
      background: $bgcolor-red;
      &:hover:not([disabled]){
        background: $bgcolor-red;
      }
    }
  }
   .mdc-floating-label ~ .DSA_wb-custom-select {
    top: 18px !important;
   }
  
  
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
  .DSA_form_nofloatLabel .mat-chip-list-wrapper{    
      border-radius: $border-radius-small $border-radius-small 0 0;
      margin: 0!important;
      /* padding:0px 8px;
      height: 35px; */
      min-height: 52px;
      transition: 0.2s all;
      background: $bggrey-lightwhite;
      &:hover{
        background: $bg-primary-50;
        box-shadow: none !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
      .mat-mdc-standard-chip, .mat-chip-list-wrapper input.mat-input-element{    
        background: none!important;
      }
      input.mat-mdc-input-element{
          padding: 6px 8px;
          &:hover:not([disabled]){
              background: none!important;
          }
      }
      /* .mat-chip.mat-standard-chip.DSA_wb_chipStyle-Big{
          margin:0;
          height: 28px;
          line-height: 28px;
          min-height: 28px;
          margin-top: -4px;
      }
      .DSA_wb_chipIconLeftBig {
          margin-left: -14px !important;
          margin-top: 1px !important;
      } */
  }
  
  /* Custom select for Web - End */