
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.DSA_wb_Secondary .mat-mdc-tab,
.DSA_wb_Secondary .mat-mdc-tab-link{
    height:40px;
    font-family: $font-style-2;
    margin: 0 0 4px;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.DSA_wb_Secondary .mat-mdc-tab:hover,
.DSA_wb_Secondary .mat-mdc-tab-link:hover{
    background: $bg-neutral-40;
    border-radius:$border-radius-big;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.DSA_wb_Secondary .mat-mdc-tab:active,
.DSA_wb_Secondary .mat-mdc-tab-link:active{
    background: $bg-primary-60;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.DSA_wb_Secondary .mdc-tab--active,
.DSA_wb_Secondary .mdc-tab--active:hover{
    background:none;
    color:$text-primary-80;  
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.DSA_wb_Secondary .mdc-tab--active:active{
    font-weight: $font-600;  
}
.DSA_wb_Secondary .mat-mdc-tab-group.mat-primary .mat-mdc-tab .mdc-tab-indicator__content--underline,
.DSA_wb_Secondary .mat-mdc-tab-nav-bar.mat-primary .mat-mdc-tab .mdc-tab-indicator__content--underline{
    height:2px;
    display: block;
}
.DSA_wb_Secondary .mat-mdc-tab-group.mat-primary :not(.mat-tab-disabled).mat-tab-label.cdk-keyboard-focused,
.DSA_wb_Secondary .mat-tab-group.mat-primary :not(.mat-tab-disabled).mat-tab-label.cdk-program-focused,
.DSA_wb_Secondary .mat-tab-group.mat-primary :not(.mat-tab-disabled).mat-mdc-tab-link.cdk-keyboard-focused,
.DSA_wb_Secondary .mat-tab-group.mat-primary :not(.mat-tab-disabled).mat-tab-link.cdk-program-focused,
.DSA_wb_Secondary .mat-mdc-tab-nav-bar.mat-primary :not(.mat-tab-disabled).mat-tab-label.cdk-keyboard-focused,
.DSA_wb_Secondary .mat-tab-nav-bar.mat-primary :not(.mat-tab-disabled).mat-tab-label.cdk-program-focused,
.DSA_wb_Secondary .mat-tab-nav-bar.mat-primary :not(.mat-tab-disabled).mat-tab-link.cdk-keyboard-focused,
.DSA_wb_Secondary .mat-tab-nav-bar.mat-primary :not(.mat-tab-disabled).mat-tab-link.cdk-program-focused{ 
    background: none!important;
    color:$text-primary-80!important;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.DSA_wb_Secondary .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination{
    margin-top: 10px;
}