app-lifes-good-see-all .dsa_cauroselhoverIcon {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: unset;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: rgba(0, 0, 0, 0);
}
.noMar{
    margin: 0px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  align-items: normal !important;
  white-space: normal !important;
  vertical-align: middle;
  width: 100%;
}

.icon.yellow{
  color: $icon-status-warning; 
}

.marNeg{
  margin-right: -55px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.DSA_Wb_custom-form-field.DSA_form_nofloatLabel .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{
display: block;
}
app-services .DSA_navTabWhite .mat-mdc-tab-header {
  background: none !important;
}
app-get-familiar .DSA_wb_customCarousel.DSA_cauroselTopbutton{

    .owl-theme .owl-nav [class*="owl-"]{
      background: none;
    }
   .owl-theme .owl-nav .owl-prev{
      left: auto ;
      right:50px;
      top:-28px;
    }
    .owl-theme .owl-nav .owl-next{
      right: 24px;
      top:-28px;
    } 
    .owl-carousel .owl-stage-outer{
      padding:20px 0;
    }
  
  }

  app-lifes-good-card-view .DSA_wb_customCarousel.DSA_wb_customCarousel_card_02 .owl-carousel .owl-stage-outer {
    padding: 16px 0;
}


 
.DSA_wb_customCarousel_smallcard .owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: 27%;
  right: 0;
  margin: 0;
  padding: 4px;
  background: transparent;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
app-manageapps .mat-checkbox-layout {
  white-space: normal !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
app-manageapps .mat-checkbox-layout .mat-checkbox-inner-container{
  margin-top: 4px !important;
}

app-profile-header .mat-expansion-indicator::after {
  padding: 4px;
}
app-cardviewtype41 .DSA_wb_customCarousel.customar .owl-theme .owl-dots {
  position: relative;
  // width: 100%;
  // bottom: -15px;
  padding-right: 32%;
  padding-top: 15px;
  padding-bottom: 10px;
  span{
    border-color: grey
  }
}
app-cardviewtype41 .DSA_wb_customCarousel.customar .owl-theme .owl-dots .owl-dot.active span
{
  background-color: purple;
}
 app-cardviewtype41 .DSA_wb_customCarousel.customar .owl-theme .owl-nav{ 
   .owl-prev {
   right: 80%;
   top: 20%;
   }
   .owl-next {
    left: 80%;
    top: 20%;
    }
  }
    // app-cardviewtype41 .DSA_wb_customCarousel.customar .owl-theme .owl-dots {
    //   width: 8px;
    // }
  
  app-event-details .listStyle ul{
        list-style: disc !important;
        padding-left: 20px !important; }

  app-event-details .listStyle ul li{
          list-style: disc !important;
          padding-bottom:16px !important;
  }
  app-work-experience .DSA_popover.popover{
    width: 372px!important;
    max-width: 372px!important;
}

  app-dailyedition .customStoriesCarousel .owl-carousel .owl-stage-outer {
    overflow: visible!important;
}

  app-cardviewtype41 .DSA_wb_customCarousel.customar .owl-theme .owl-dots .owl-dot span{ 
    width: 8px;
    height: 8px;
  }
  .pre-icon{
    background-image: url('/assets/images/cust-carousel-left.png');
  }
  .nex-icon{
    background-image: url('/assets/images/cust-carousel-right.png');
  }





// .cdk-drag-placeholder {
//   opacity: 0;
// }

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.custom-placeholder {
  // background: #ccc;
  // border: dotted 3px #999;
   height: 100px;
   transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
   background:$border-neutral-40;
   border:1px dashed $bg-neutral-80;
   border-radius: $border-radius-extrabig;
}


@media (min-width: 991px){
  .DSA_modal-lg-custom .modal-dialog {
      min-width: 776px;
  }
  }

  
.clsHideY{
  overflow-y: hidden !important;
}


.cdk-drag[ng-reflect-disabled="false"]:nth-child(2) > div {
//  border: 2px dashed #8626C3;
  cursor: move;
}
//genmodel-type2 starts//
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
app-gen-model-type2 .DSA_Wb_custom-checkbox .mat-checkbox-layout .mat-checkbox-label {
  color: #423d3d;
}
// app-gen-model-type2 .mat-radio-button.mat-accent .mat-radio-inner-circle {
//   background-color: purple!important;   /*inner circle color change*/
// }

// app-gen-model-type2.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
//  border-color:purple!important; /*outer ring color change*/
// }
app-gen-model-type2 .mat-mdc-form-field {
  font-size: inherit;
  width: 100%;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto,"Helvetica Neue",sans-serif;
}
// app-gen-model-type2 .mat-form-field-appearance-legacy .mat-form-field-infix {
//   padding: .4375em 0;
//   font-size: 15px;
// }
app-gen-model-type2 .mat-mdc-unelevated-button:not([class*=mat-elevation-z]), .mat-mdc-outlined-button:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
  /* color: red; */
  margin-left: 15px;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
app-gen-model-type2 .DSA_Wb_custom-checkbox .mat-checkbox-layout .mat-checkbox-label {
  color: #423d3d;
  font-size: 15px;
}
app-gen-model-type2 .DSA_wb-cus-accordion .mat-expansion-panel .mat-expansion-panel-header {
  height: 40px !important;
  padding: 25px 17px;
  font-size: 1.6rem;
  font-weight: 500;
  font-style: normal;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
app-gen-model-type2 .customAcc.mat-expansion-panel-header-description, .mat-expansion-panel-header-title {
  display: flex;
   flex-grow: 0 !important; 
  margin-right: 16px;
}

//genmodel-type2 ends

app-header .signInSec .mat-expansion-panel-header {
  padding: 0 0px!important;
}
app-header .signInSec .mat-expansion-panel-header-title {
  display: block;
}
app-header .signInDelegation .mat-expansion-panel-body {
  padding: 0 4px 16px !important;
}
app-header .signInDelegation .mat-expansion-panel {
  background: #fafafa!important;
}
app-work-experience  .DSA_popover.popover{
    width: 372px!important;
    max-width: 372px!important;
}
app-discover-innovation-details .lStyle ul{
  list-style: disc !important;
  padding-left: 20px !important;
}
app-discover-innovation-details .lStyle ul li{
  list-style: disc !important;
  padding-bottom:12px !important;
}
app-discover-innovation-details .lStyle ul li:last-child{
  list-style: disc !important;
  padding-bottom:4px !important;
}
app-discover-innovation-details .ulStyle ul{
  list-style: disc !important;
  padding-left: 20px !important;
}
app-discover-innovation-details .ulStyle ul li{
  list-style: disc !important;
  padding-bottom:16px !important;
}
app-discover-innovation-details .ulStyle ul li:last-child{
  list-style: disc !important;
  padding-bottom:0px !important;
}
app-discover-innovation-details .ulStyle ul li ul {
  list-style: circle !important;
  padding-top:16px !important;
}
app-discover-innovation-details .ulStyle ul li ul li{
  list-style: circle !important;
  color: #555555;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  font-family: roboto,Arial,sans-serif!important;
  font-style: normal;
}
app-discover-innovation-details .ulStyle ul li ul li:last-child{
  list-style: circle !important;
  color: #555555;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  font-family: roboto,Arial,sans-serif!important;
  font-style: normal;
  padding-bottom: 0px !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.vaccinationDate.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{
  border-bottom: 1px solid #DADADA!important;
  :hover{
    cursor: no-drop;
  }
}


/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.vaccinationDatechk.DSA_Wb_custom-checkbox.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .DSA_Wb_custom-checkbox.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{
  background: var(--wb-color-dark) !important;
  :hover{
    cursor: no-drop;
  }
}

.clsVaccineSlotYes{
  max-height: 510px;
}

.clsVaccineSlotNo{
  max-height: 250px;
}
.width20{
width: 20%;
}
.width25{
  width: 25%;
  }
.width35{
    width: 35%;
    }
.Cust_cardview40{
    position: absolute !important;
    width:  270px !important;
}

//Comp CSS


.DSA_headersearchInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 5px !important;
  background-attachment: #FFFFFF !important;
  padding: 0 16px !important;
  line-height: 40px !important;
  font-size: 1.4rem !important;
  border: 0 !important;
  background-color: #FFFFFF !important;
}
.DSA_RightUtil li {
  float: left;
  margin-left: 16px;
}
.icon-48 {
  font-family: DS-iconfont !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1;
  vertical-align: middle;
  font-size: 48px;
}
.DSA_Hamburger.marT24 {
  margin-top: 24px !important;
}
.DSA_Applogo {
  font-weight: 500;
  font-size: 1rem;
  color: #FFFFFF;
  margin-top: 16px;
  line-height: 40px;
}
.icon-56 {
  font-family: DS-iconfont !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1;
  vertical-align: middle;
  font-size: 56px;
}
.DSA_headersearchInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 5px !important;
  background-attachment: #FFFFFF !important;
  padding: 0 16px !important;
  line-height: 40px !important;
  font-size: 1.4rem !important;
  font-size: 16px!important;
  border: 0 !important;
  background-color: #FFFFFF !important;
}

.sp2_labelField {display:inline-block;width:auto;margin-right:10px;}
.sp2_labelField {
  display: inline-block;
  width: auto;
  margin-right: 10px;

}
.sp2_newInfyTheme .sp2_labelField .form-control.sp2_dateRanger {
  border-radius: 0;
  box-shadow: none;
  text-align: left;
  padding-left: 0;
  background-image: url("/assets/images/calendar-icon.png");
  background-repeat: no-repeat;
  display: inline-block;
  background-position: right center;
  margin: 6px 0;   
  /* added for bus pass */
}





.sp2_labelField {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}

.sp2_wall_tab .sp2_formBlock {
 margin-right: 0;
 min-width: 135px
}

.searchResultpage .sp2_formBlock{ min-width: auto;margin-right: 0}

.sp2_formBlock {
min-width: 150px;
display: inline-block;
margin-right: 68px;
vertical-align: top;
position: relative;
left: 36px;
margin-top: 26px;
width: 1px;

}

.sp2_formBlock2{
min-width: 300px !important;
}
.sp2_form-label {
text-transform: none;
}
.sp2_form-label {
font-size: 12px;
font-weight: normal;
color: #606060;
display: block;
padding-left: 0;
text-align: left;
text-transform: uppercase;
margin-bottom: 0;
font-family: "RobotoMedium";
}
.sp2_form-label {
font-size: 12px;
font-weight: normal;
color: #898989;
display: block;
padding-left: 0;
/*padding-top: 40px;*/
text-align: left;
/*text-transform: uppercase;*/
margin-bottom: 0;
font-family: "RobotoMedium";
}
.sp2_approverEnrollLabel {margin:10px 0;}
.sp2_approverEnrollLabel {
margin: 7px 0;
margin-bottom: 10px;
}
.enrollLabel{
top: 290px;
left: 873px;
width: 130px;
height: 43px;
text-align: left;
font-size: 15px;
font-family: "Roboto";
letter-spacing: 0.16px;
color: #231F20;
opacity: 1;
}
.sp2_discalimer-sm {
font-style: italic;
font-size: 11px;
margin-bottom: 10px;
color: #a4a4a4;
}
.sp2_customCombo .sp_btnStye2.btn-primary {
padding: 5px 20px;
margin-top: 15px;
font-size: 11px;
}
.sp2_customCombo .sp_btnStye2.btn-primary {
padding: 5px 20px;
margin-top: 15px;
font-size: 11px;
}
.sp2_customCombo {
text-align: center;
}
.sp2_customCombo .sp_btnStye2.btn-primary {
padding: 5px 20px;
margin-top: 15px;
font-size: 11px;
}
.sp2_customCombo .select2-container--default .select2-selection--single .select2-selection__rendered {
text-align: center;
border: none;
border: 1px solid #ef6b52;
padding: 6px 15px;
border-radius: 15px;
text-transform: uppercase;
background-color: #fff;
color: #ef6b52;	
font-weight: bold;
font-size: 12px;
height: 30px;
}

.sp2_newInfyTheme .sp2_customCombo .select2-container--default .select2-selection--single .select2-selection__rendered {
border: none;
border-radius: 0;
border-bottom: 1px solid #ccc;
color: #8626c3;
text-align: left;
padding-left: 0;
min-width: 150px;
padding-right: 45px;
}
.sp2_customCombo .select2-container {
min-width: 115px;
}
.sp2_customCombo .sp_btnStye2.btn-primary {    padding: 5px 20px;margin-top:15px;font-size:11px;}
.container {
 display: block;
 position: relative;
 padding-left: 35px;
 margin-bottom: 12px;
 font-size: 16px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
}
/* Hide the browser's default checkbox */
.container input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
 height: 0;
   width: 0;
   
}
.csCheckbox{
 text-align: justify;
 padding-left:20px !important;
}
/* Create a custom checkbox */
.checkmark {
position: absolute;
/* top: 0; */
/* left: 461px; */
/* margin-left: -232px;  */
height: 17px;
width: 18px;
background-color: white;
border: 0.2px solid #8626c3;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
background-color: white;
}

/* When the checkbox is checked, add a  background */
.container input:checked ~ .checkmark {
/* background-color: #2196F3; */
background: url("/assets/images/check-on-purple.png") no-repeat;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
display: block;
}
.tapItRemote{
color:#666666;
}

.tapItRemote:hover{
color:#8626c3;
}
.tapItRemote:hover{
cursor: default;
}

.tapItRemote{
padding-left: 28px;

}  
.sp2_formField {display:inline-block;width:auto;margin-right:10px;}
.sp2_formField {
display: inline-block;
width: auto;
margin-right: 10px;
padding-bottom: 20px;
}
.sp2_approverLabel {margin:10px 0;}
.sp2_approverLabel input {border:none; border-bottom:1px solid #333;padding-bottom:5px;display:inline-block;margin:0 5px;}
.sp2_approverLabel input {
border: none;
color:#212121;
border-bottom: 1px solid #ccc;
padding-bottom: 8px;
display: inline-block;
margin: 0 5px;
font-family: "Roboto", Arial;
}
.sp2_approverLabel {
margin: 7px 0;
margin-bottom: 7px;
}
.form-input{
font-size: 14px;
/* font-weight: bold; */
color: #212121;
}
.marginT15 {
margin-top: 15px !important;
} 
.sp2_radio-label {
 min-width: 300px;
 font-size: 12px;
 font-weight: normal;
 color: #898989;
 display: block;
 padding-left: 0;
 text-align: left;
 text-transform: uppercase;
 margin-bottom: 0;
 font-family: "RobotoMedium";
}
.clsSelect{
margin: 7px 0;
}
.select-selected {
background-color: transparent;
border-bottom: 1px solid #ccc;
color: #212121;
width: 150px !important;
/* font-weight: bold; */
outline: none;
}
.select-selected option{
color: black !important;
padding: 8px 16px;
border: 1px solid transparent;
border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
cursor: pointer;
/* color: #8626c3; */
}
.sp2_radioBlock {
 min-width: 300px;
 display: inline-block;
 margin-right: 25px;
 vertical-align: top;
 position: relative;
 text-align: left;
}

.sp2_date{
min-width:300px !important;
margin-top:28px;
left:36px;
}


.sp2_radio-label {
 min-width: 300px;
 font-size: 12px;
 font-weight: normal;
 color: #898989;
 display: block;
 padding-left: 0;
 text-align: left;
 text-transform: uppercase;
 margin-bottom: 0;
 font-family: "RobotoMedium";
}
.clsSelect{
margin: 7px 0;
}
.leftmargin{
left: -18px;
}
.dependent{
margin-left: 1063px;
font-size: 15px;
font-weight: 500;
margin-bottom: -18px;
}
.select-selected {
background-color: transparent;
border-bottom: 1px solid #ccc;
color: #212121;
width: 150px !important;
/* font-weight: bold; */
outline: none;
}
.select-selected option{
color: black !important;
padding: 8px 16px;
border: 1px solid transparent;
border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
cursor: pointer;
/* color: #8626c3; */
}
.sp2_radioBlock {
 min-width: 300px;
 display: inline-block;
 margin-right: 25px;
 vertical-align: top;
 position: relative;
 text-align: left;
}
.sp2_radioField {display:inline-block;width:auto;margin-right:40px;}
.sp2_radioField {
display: inline-block;
width: auto;
margin-right: 40px;
padding-bottom: 20px;
}
.sp2_newInfyTheme .sp2_radioField .form-control.sp2_dateRanger {
border-radius: 0;
box-shadow: none;
text-align: left;
padding-left: 0;
background-image: url("/assets/images/calendar-icon.png");
background-repeat: no-repeat;
display: inline-block;
background-position: right center;
margin: 6px 0;   
/* added for bus pass */
}
.sp2_radioField {
display: inline-block;
width: auto;
min-width: 40px;
}
.sp2_reasonLeaveTxt textarea {color:#898989;border:1px solid #898989;border-radius:4px;padding:10px;width:40%;margin:20px 0}

.sp2_reasonLeaveTxt textarea {
color: #898989;
border: 1px solid #D2D2D2;
border-radius: 4px;
padding: 10px;
width: 40%;
margin: 0px 0 7px 0;
}
.innerContent ul{
padding-left: 20px !important;
}
.innerContent ul li{
   list-style-type: disc !important;
}
.innerContent img{
  margin-top: 24px;
  margin-bottom: 24px;
}

  .mat-expansion-panel-header-title {
      color: $grey-light
  }
  .mat-expansion-panel-body {
      background: $white-color;
      padding: 0
  }
.icon-102 {
  font-family: DS-iconfont !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1;
  vertical-align: middle;
  font-size: 114px;
}
.DSA_wb-cus-accordion1 .mat-expansion-panel .mat-expansion-panel-body {
  background: #ffffff;
  padding: 0;
} 
.background_expansion {
  border-top-right-radius: 16px!important;
  border-top-left-radius: 16px!important;
  border-bottom-right-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  box-shadow: none!important;
}
.DSA_wb-cus-accordion1 .mat-expansion-panel .mat-expansion-panel-header {
  height: 90px !important;
  padding: 0 16px;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  border-radius: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.icon-16 {
  font-family: DS-iconfont !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1;
  vertical-align: middle;
  font-size: 16px;
}
.DSA_h5Header {
  font-weight: 500;
  font-size: 1.4rem;
  font-size: 14px !important;
}
.DSA_listItemSingle li > a:hover {
  text-decoration: none;
  color: #737373;
  background-color: #EFEFEF;
}
.b-font{
font-size: 12px !important;
}
.b-font14{
font-size: 14px !important;
}

// app-notifications-teams .DSA_wb_Secondary .mat-tab-label:active, .DSA_wb_Secondary .mat-tab-link:active {
//   background: none !important;
// }
// app-notifications-teams .DSA_Wb_custom-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background, 
//  .DSA_Wb_custom-checkbox.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background{
//   background: blue;
//   border: none;
// }
// app-notifications-teams  .DSA_Wb_custom-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-inner-container:hover .mat-checkbox-frame{
//   border-color: blue;
// }


app-race-and-ethnicity  ul li{
  list-style-type: disc!important;
  padding: 8px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-reach-out .DSA_wb_Primary .mat-mdc-tab{
  height:35px;
  font-family: $wb-font-style-2;
  border-radius: 49px;
  margin-right: 10px;
  background: #EFEFEF;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-reach-out .DSA_wb_Primary .mdc-tab--active{
  background: #edcfff;
  color:#8626c3;
  border-radius: 49px;
  padding: 15px;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-reach-out .DSA_wb_customTab .mat-mdc-tab, .DSA_wb_customTab .mat-mdc-tab-link {
  color: black;
  font-size: 13px;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-reach-out .DSA_wb_Primary .mdc-tab--active,
.DSA_wb_Primary .mdc-tab--active:hover{
  background: #edcfff;
  color:#8626c3;

}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-reach-out .DSA_wb_Primary .mat-mdc-tab:hover, .DSA_wb_Primary .mat-mdc-tab-link:hover {
  
  border: 1px solid #8626c3;
  color: #8626c3;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-reach-out .DSA_wb_Primary .mat-mdc-tab, .DSA_wb_Primary .mat-mdc-tab-link {
  
  border: 1px solid transparent;
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-reach-out .DSA_wb_customTab .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination{
  height: 24px;
  width: 24px;
  min-width: 24px;
  padding: 44px;
  margin: 7px;
  /* display: inline-block; */
  padding: 0px;
  background: #FFFFFF;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.notificationsTabs .DSA_wb_customTab .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  margin-top: 8px!important;
  // background: #fafafa!important;
}
.notificationsTabs .DSA_navTabWhite .mat-mdc-tab-header {
background: #fafafa!important;
}
.notificationsTabs .mat-expansion-panel {
background: transparent !important;
border-bottom: 1px solid #EFEFEF !important;
margin-top: 0px !important;
}
.notificationsTabs .mat-expansion-panel:hover
{
// background: #FFFFFF 0% 0% no-repeat padding-box!important;
box-shadow: 0px 8px 16px #0000003D!important;
padding:0px 24px;
margin-left: -24px;
margin-right: -24px;
}
.notificationsTabs .expendedPanel
{
background: transparent!important;
}
.notificationsTabs .expendedPanel:hover
{
background: transparent!important;
}
.notificationsTabs .mat-expansion-panel:hover .notificationsTabs .mat-expansion-panel-header-title 
{
font-weight: normal!important;
}
.notificationsTabs .mat-expansion-panel-header {
font-size: 14px;
// font-weight: 600!important;
font-family: myriad-pro,Arial,sans-serif!important;
}
.notificationsTabs .DSA_wb_card-widget.mat-mdc-card {
border-radius: 8px!important;
}
.notificationsTabs .DSA_greychip {
background: transparent!important;
}
.notificationsTabs .mat-expansion-panel-header-title {
color: #7e7e7e!important;
}
.notificationsTabs .mat-expansion-panel-spacing{
margin-bottom: 0px!important;
}
.storeSlider .owl-carousel .owl-stage-outer {
  padding: 12px 0px 20px 0px!important;
}
.storeSlider .owl-carousel .owl-item {
  height: 152px;
}

.DSA_wb_listItemSingle li>a:active{
  text-decoration: none;  
  color: $wb-color-thick;
  background-color: $wb-grey-lighter; 
  h2{
    color: $wb-color-thick;
  }
  div>i.icon, div>i.icon.purple{
    display: none;
  }
  div>i.icon.dark-purple{
    display: block;
  }
}
.DSA_wb_listItemSingle li.active>a{
  background-color: transparent;
  //border-left:4px solid $wb-color-dark;
  h2{
    color: $wb-color-dark;
    font-weight: $wb-text-semibold;
    font-style: normal;
  }
  div>i.icon, div>i.icon.dark-purple{
    display: none;
  }
  div>i.icon.purple{
    display: block;
  }  
}
app-rewards-summary .DSA_modal-sm{
  .modal-dialog{
    min-width: 420px !important
  }
}
app-claimsSubmission h6{
font-size: 16px;
}
app-claimsSubmission .parallelText{
  font-size: 14px;
}
app-claimsSubmission span.icon-btn-txt {
  font-size: 14px;
}

app-claimsSubmission ul.attachment_info{
  font-size: 14px;
}
app-claimsSubmission ul.attachment_info{
  font-size: 14px;
}
app-claimsSubmission div.web-SecondaryBlueBg{
  background: #3367D6;
  color: #FFFFFF;
}
@media(min-width:1024px){
  .DSA_modal-xlg{
    
    .modal-dialog{
      min-width: 994px;
    }
  }

  .DSA_modal-xlg-border{
     .modal-content  {
      border-radius: 16px;
      border: 0px solid rgba(0, 0, 0, 0.2);
      border-radius: 16px;    
}
  }
}

@media(min-width:1024px){
  .DSA_modal-lg{
    
    .modal-dialog{
      min-width: 994px;
    }
  }

  .DSA_modal-lg-border{
     .modal-content  {
      border-radius: 16px;
      border: 0px solid rgba(0, 0, 0, 0.2);
      border-radius: 16px;    
}
  }
}
@media(min-width:1024px){
  .DSA_modal-xlg{
    
    .modal-dialog{
      min-width: 994px;
    }
  }

  .DSA_modal-xlg{
     .modal-content  {
      border-radius: 16px;
      border: 0px solid rgba(0, 0, 0, 0.2);
      border-radius: 16px;    
}
  }
}
@media(min-width:1024px){
  // .DSA_modal-sm{
    
  //   .modal-dialog{
  //     min-width: 994px;
  //   }
  // }

  .DSA_modal-sm{
     .modal-content  {
      border-radius: 16px;
      border: 0px solid rgba(0, 0, 0, 0.2);
      border-radius: 16px;    
}
  }
}
.DSA_wb_flex{
  display: flex;
  grid-template-columns:80% 20%;
}
.DSA_matCard-imageLeft{
  float:left;
  width:80px;
 height: 212px;
  
}
.DSA_leftImg{
 margin-left: 80px;
 height: 212px;
}
.DSA_matCard-imageRight{
 float:right;
 width:80px;
 height: 212px;
 
}
.DSA_rightImg{
 margin-right: 80px;
height: 212px;
}



//,,,,
.DSA_wb_customCarousel{
  .owl-theme .owl-nav{
    margin: 0;
  }
  .owl-theme .owl-nav [class*="owl-"]{
    position: absolute;
    top: 43%;
    right: 0;
    margin: 0;
    padding: 4px;
    background: transparent;
  }
  .owl-theme .owl-nav .owl-prev{
    left: 0;
    right: auto;
  }
  .owl-theme .owl-dots{
    position: absolute;
    width: 100%;
    left: 25px;
    bottom: 8px;
  }
  .owl-theme .owl-dots .owl-dot{
    padding: 2px;
    margin: 0 8px;
    text-align: center;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
  }
  .owl-theme .owl-dots .owl-dot span{
    background: none;
    border: 1px solid #737373;
    @extend %wb-default-shadow;
    width: 12px;
    height: 12px;  
    margin: 0;  
  }
  .owl-theme .owl-dots .owl-dot.active{
    background: rgba(0, 0, 0, 0);
    span{
      background: $wb-grey-white;
    }    
  }
  .owl-theme .owl-dots .owl-dot:hover{
    background: rgba(0, 0, 0, 0.04);    
  }
  .owl-theme .owl-dots .owl-dot:focus{
    background: rgba(0, 0, 0, 0.08);  
    span{
      border: 1px solid $wb-grey-lightest;
      background: $wb-grey-lightest;
    }  
  }
  .carousel_content{
    position: absolute;
    top: 12%;
    left: 16px;
    right: 16px;
    width: 310px;    
    z-index: 1;
    .DSA_wb_displayText{
      font-size: $wb-heading-lg;
      font-weight: $wb-text-medium;
      font-family: $wb-font-style-1;
      font-style: normal;
    }
    .DSA_wb_h1Header{
      font-size: $wb-heading-sm;
      font-weight: $wb-text-medium;
      font-family: $wb-font-style-1;
      font-style: normal;
    }
    .DSA_wb_mainBodyTxt{
      font-size: $wb-text-md;
      font-style: normal;
    }
    .DSA_cauroselP{
      height: 130px;
      overflow: hidden;
    }
  }    
  .owl-item .DSA_wb_card-widget{
    margin: 1px;
  }  
  .owl-theme .owl-nav .disabled{
    opacity: 0;
  }  
  .owl-item img.banner_mobileXS{
    display: block;
  }
  .owl-item img.banner_desktop{
    display: none;
  } 
  .carousel_overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    background: rgba(13, 81, 131, 0.60);
  }  
}
//,,,,

//
app-lifes-good .dsa_cauroselhoverIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: unset;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: rgba(0, 0, 0, 0);
}
app-lifes-good .DSA_wb_listLeft {
  width: 75%;
  float: left;
  padding-right: 8px;
  position: relative;
}
.dsa_cauroselhoverIconContent{
  display:none;
}
.dsa_cauroselhover:hover .dsa_cauroselhoverIconContent{
  display:block;
  transition: all 0.5s;
}
app-lifes-good .dsa_cauroselhover:hover .dsa_cauroselhoverIcon{
  opacity:1;
  background: none;
  transition: all 0.5s;
}
app-lifes-good .DSA_wb_mainBodyTxtXS {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "roboto", Arial !important;
  font-style: normal;
}
/*life'sGood*/
.DSA_wb_customCarousel.DSA_wb_customCarousel_card_02{
  .owl-carousel .owl-stage-outer{
    padding: 20px 0;
  }
  .owl-theme .owl-nav [class*="owl-"]{
    background: $bg-white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    &:hover{
      @extend %carouselIcon-shadow !optional
    }
  }

  .owl-theme .owl-nav .owl-prev{
    left: -24px;
    a.icon{
      margin: 8px auto 0;
    }
  }
  .owl-theme .owl-nav .owl-next{
    right: -24px;
    a.icon{
      margin: 8px auto 0;
    }
  }  
}
//

//--
.DSA_wb_customCarousel.DSA_wb_customCarousel_card_02{
  .owl-carousel .owl-stage-outer{
    padding: 16px 0;
  }
  .owl-theme .owl-nav [class*="owl-"]{
    background: $bg-white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    &:hover{
      @extend %carouselIcon-shadow !optional
    }
  }

  .owl-theme .owl-nav .owl-prev{
    left: -24px;
    a.icon{
      margin: 8px auto 0;
    }
  }
  .owl-theme .owl-nav .owl-next{
    right: -24px;
    a.icon{
      margin: 8px auto 0;
    }
  }  
}
.DSA_card-rounded.mat-mdc-card {
  border-radius: 16px;
  padding: 24px;
}
.DSA_wb_text-1line{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
//--

.DSA_matCard-imageCtr.DSA_matCard-imageTop{
  height: 200px;
  margin: 0 -16px 16px;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  .mat-mdc-card-image{
    width: auto;
    position: absolute;
    left: 50%;
    @include transform(translate(-50%, 0px));
  }
}

.DSA_wb_card-widget.mat-mdc-card .mat-mdc-card-actions.card-actions-hover {
  position: absolute;
  width: 100%;
  bottom: -30px;
  -webkit-transition: bottom 0.2s ease 0.2s;
  transition: bottom 0.2s ease 0.2s;
  background: #ffffff;
}
.DSA_wb_card-widget.mat-mdc-card .mat-mdc-card-actions:last-child {
  margin-bottom: -16px;
}
.DSA_wb_card-widget.mat-mdc-card .mat-mdc-card-actions {
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0px -3px 2px 0px rgba(204, 204, 204, 0.1);
}
.DSA_wb_card-widget.mat-mdc-card .mat-mdc-card-subtitle {
  margin-bottom: 24px;
}
.DSA_wb_mainBodyTxtXS {
  font-size: 1rem;
  font-weight: 400;
  font-family: "roboto", Arial !important;
  font-style: normal;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.DSA_wb_card-widget.mat-mdc-card .mat-card-header-text {
  width: 100%;
  margin: 0;
}
// .DSA_wb_customCarousel .owl-item .DSA_wb_card-widget {
//   margin: 1px;
// }
.DSA_card-rounded.mat-mdc-card {
  border-radius: 16px;
  padding: 24px;
}
.DSA_wb_card-widget.DSA_wb_card-widget-hidden {
  overflow: hidden;
}

//css bb
/* Custom Cards - Start */
.DSA_wb_title{
  font-family: $font-style-2;
  font-size: $size-hd-48;
  font-weight: $font-500;
  font-style: normal;
  color: $text-black;
}
.DSA_wb_card-widget.DSA_wb_card-widget-hidden{
  overflow: hidden;
}

.DSA_wb_card-widget.mat-mdc-card{
  padding: 24px;
  @extend %default-shadow;    
  min-height: 50px;  
  &:hover{
    @extend %hover-shadow; 
    .DSA_wb_title, .DSA_wb_h2Header{
      color: $text-primary-80;
    } 
    .mat-mdc-card-actions.card-actions-hover{      
      bottom: 24px;
    }   
  }
  .mat-mdc-card-content{
    position: relative;
    min-height: 40px;
    margin-bottom: 24px;
    .close-icon{      
      cursor: pointer;
    }
  }
  .mat-mdc-card-subtitle{
    margin-bottom: 24px;
  } 
  .mat-mdc-card-actions{
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    -webkit-box-shadow: 0px -3px 2px 0px rgba(204,204,204,0.1);
    -moz-box-shadow: 0px -3px 2px 0px rgba(204,204,204,0.1);
    box-shadow: 0px -3px 2px 0px rgba(204,204,204,0.1);
    .DSA_wb_text-btn:first-child{
      margin-left: 5px !important;
    }
  }
  .mat-mdc-card-actions:last-child{
    margin-bottom: -16px;
  }
  .mat-mdc-card-actions.card-actions-hover{
    position: absolute;
    width: 100%;
    bottom: -30px;
    transition:bottom 0.2s ease 0.2s;
    background: $bg-white;    
  }
  .DSA_wb_flex80{    
    float: left;
    width: 80%;
  }
  .DSA_wb_flex20{
    float: right;
    width: 20%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  .mat-card-header-text{
    width: 100%;
    margin: 0;
  }
  hr{
    border-top: 1px solid $bg-neutral-40;
    margin: 0 -16px;  
  }  
  hr.DSA_marhr{
    margin: 16px 0;  
  }
  hr.nomar{
    margin: 0;  
  }
  .DSA_matCard-imageCtr{    
    margin: 0 -24px 24px;
  }
  // .DSA_matCard-imageCtr .mat-card-image{
  //   width:100%;
  //   margin: 0;
  // }
  .DSA_matCard-imageCtr .mat-mdc-card-image:first-child{
       margin-top: 0;
  }  
}
.DSA_card-rounded.mat-mdc-card{
  border-radius: $border-radius-16;
  padding:24px;
}
.DSA_card-rounded.mat-mdc-card .mat-mdc-card-content{
  margin-bottom: 0;
}
.DSA_wb_Smallcard-widget.mat-mdc-card{
  padding: 16px;
}
.DSA_wb_Smallcard-widget.mat-mdc-card .mat-mdc-card-actions{
  margin:24px -16px;
}
.DSA_wb_card-widget.mat-mdc-card.nopad_wb{
  padding: 0 !important;
}

.DSA_matCard-imageLeft{
  position: absolute;  
  width:80px;
  height: 100%;
  left: 0;
  top: 0;
  img{
    width: 100%;
    height: 100%;
  }  
}
.DSA_leftImg{
 margin-left: 80px; 
}
.DSA_matCard-imageRight{
 position: absolute;
 width:80px;
 height: 100%;
 right: 0;
 top: 0;
 img{
   width: 100%;
   height: 100%;
 } 
}
.DSA_rightImg{
 margin-right: 80px;
}
.DSA_matCard-imageCtr.DSA_matCard-imageTop{  
  height: 80px;
  position: relative; 
  img, .media_bg{
    height: 100%;
  }
}
.DSA_absTop{
  position: absolute;
  left:24px;
  top:24px;
  width:calc(100% - 48px);
  z-index: 2;
}

.DSA_profilePad{
  margin-left: 48px;
  padding-top:16px;
}
.DSA_wb_card-widget.DSA_card-square{
  min-width: 100px;
  max-width: 100px;
  float: left;     
}
.DSA_wb_card-widget.DSA_card-square.DSA_square-lg{
  min-width: 172px;
  max-width: 172px;
}
// .DSA_wb_card-widget.DSA_wb_actionCard{
//   min-width: 274px;
//   max-width: 274px;
// }
.DSA_wb_text-1line{
  overflow: hidden;
  padding-bottom: 1px;
  text-overflow: ellipsis;
  padding-bottom: 1px;
  white-space: nowrap;
  display: block;
}
.DSA_wb_text-3line{
  height: auto ;
  overflow: initial;
}  

.option_ul{
  li{
    width: auto;
    float: left;
    a{
      width: 100%;
      background-color: $bg-white;
      border-radius: 4px;
      min-height: 88px;
      padding: 24px;      
      display: block;      
      @extend %default-shadow; 
      position: relative;
      border: 2px solid transparent;
      transition: box-shadow 280ms cubic-bezier(.4,0,.2,1), background-color 280ms linear;
      div.DSA_wb_mainBodyTxt{
        color: $text-neutral-80;
        line-height: 40px;
        display: block;
        margin: 0 0 0 48px;
      }
      &:hover{
        @extend %hover-shadow;                     
        div.DSA_wb_mainBodyTxt, i.icon{
          color: $text-black;
        }
      }
      &:focus{
        @extend %active-shadow; 
        background-color: $bg-neutral-40;        
        .whiteIconNoshadow-Circle{
          background: $bg-neutral-40;          
        }
        i.icon{
          color: $text-primary-100;
        }
        div.DSA_wb_mainBodyTxt{
          color: $text-primary-100;
          font-weight: $font-500;
        }
      }         
    }
    a:hover, a:focus{
      text-decoration: none;
      outline: none;
    }
    a.icon_withAction.selected{
      border: 2px solid $bg-primary-80;
      background-color: $bg-white;
      
      .whiteIconNoshadow-Circle{
        background: transparent;        
      }
      i.icon{
        color: $text-primary-80;
      }
      div.DSA_wb_mainBodyTxt{
        color: $text-primary-80;
        font-weight: $font-500;
      }
    }        
  }
  li.option_mini{  
    text-align: center;  
    div.DSA_wb_mainBodyTxt{
      line-height: 1;
      margin: 0;
    }
  }
}



.mat-mdc-card-image.custom-card-image-bg{
    margin-bottom: -16px;
}
.DSA_card-image-bg{    
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  border-radius: 4px;
}
.DSA_card-image-bg img{
  width: 100%;
  height: 100%;
}
.DSA_txt-overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.5);
  top:0;
  left: 0;
  border-radius: 4px;
  z-index: 1;
}
.DSA_txt_top{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  hr{      
    opacity:0.5;
  }
}
.DSA_txt_top > .mat-mdc-card-actions:last-child {
  margin-bottom: -8px;
  padding-bottom: 0;
}  
.DSA_card-withRtImg .DSA_txt_left{
    margin-right:106px;
}
.DSA_card-withRtImg .DSA_txt_left.txt-withAvatar{
  margin-right: 96px;
}
.DSA_card-withRtImg .img-cont{
  position: absolute;
  top: 0;
  right: 0;
}
.DSA_card-withLtImg .img-cont{
  position: absolute;
  top: 0;
  left: 0;
}
.mat-mdc-card:not([class*="mat-elevation-z"]){
  @extend %default-shadow;
}
.DSA_wb_card-widget.DSA_card-withRtImg, .DSA_wb_card-widget.card-withTxt,.DSA_wb_card-widget.DSA_card-withLtImg{
  min-height: 106px;
}

.DSA_wb_card-widget.DSA_card-withRtImg.card-lg,.DSA_wb_card-widget.DSA_card-withLtImg.card-lg{
  min-height: 136px;
  max-height: 136px;
}
.DSA_card-withRtImg.card-lg .img_leader{
  margin: 12px 0 0;
}
.DSA_card-withRtImg .card-avatar{
  margin: -4px 0 0;
}  
.DSA_wb_card-widget.mat-mdc-card.dark > .mat-mdc-card-actions:last-child{
  margin: 0;
  padding: 0;
}
.DSA_text-1line{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.DSA_text-2line{
  height: 42px;
  overflow: hidden;
}
.DSA_text-3line{
  height: 54px;
  overflow: hidden;
}  
.DSA_text-4line{
  height: 72px;
  overflow: hidden;
}
.DSA_h4Header.DSA_text-2line{
  height: 32px;
}
p.DSA_text-3line{
  height: 63px;    
}

/*.DSA_wb_card-widget ~ .DSA_wb_card-widget{
  margin: 0 0 0 32px;
}*/
.DSA_wb_card-widget.DSA_card-withLtImg .DSA_txt_left{
  padding-left: 120px;
}
.DSA_wb_card-widget .v-menu-icon{
  background-position: center;
}
.DSA_card-ul{
  list-style: none;
  padding: 0;
  li{
    padding: 8px 0;      
  }
  li:last-child{
    padding: 8px 0 0;
  }
  li.DSA_border-top{
    border-top: 1px solid $bg-neutral-40 !important;
  }
}  
.DSA_action-ul{
  margin: 4px 0;
  li{
    float: left;
    margin: 0 0 0 8px;
  }
}
.DSA_card-fixed{
  max-width: 156px;
  min-width: 156px;
  height: 156px;
  float: left;
  .align-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.mat-mdc-card-actions .mat-mdc-button, .mat-card-actions .mat-mdc-raised-button{
  margin: 0 !important;
}
.DSA_wb_card-widget.DSA_card-nobg{
  background: transparent;
  box-shadow: none !important;
  padding: 0;
}
.DSA_wb_card-widget.DSA_card-withLtImg .DSA_txt_top .DSA_txt_left{
  padding-left: 72px;
  min-height: 56px;
}
.DSA_wb_card-widget .v-menu-icon.bottom{
  top: auto;
  bottom:16px;
  right: 8px;
  position: absolute;
}
.DSA_wb_card-widget .DSA_wb_card-image-bg{    
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  border-radius: 4px;
}
.DSA_wb_card-widget .DSA_wb_card-image-bg img{
  width: 100%;
  height: 100%;
}
.DSA_wb_card-widget .DSA_wb_txt-overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.5);
  top:0;
  left: 0;
  border-radius: 4px;
  z-index: 1;
}
.DSA_wb_card-widget .DSA_wb_txt_top{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  hr{      
    opacity:0.25;
    margin: 0 -24px;
    border-top: 1px solid $bg-white;
  }
  
}
.DSA_wb_card-widget .DSA_wb_txt_top > .mat-mdc-card-actions:last-child {
  margin-bottom: -16px;
  padding-bottom: 0;
} 
.DSA_selectionCard .DSA_selectedIcon{
  display: none;

}
.DSA_selectionCard .DSA_unselectedIcon{
  display: inline-block;

}
.DSA_selectionCard.DSA_active .DSA_selectedIcon{
  display: inline-block;

}
.DSA_selectionCard.DSA_active .DSA_unselectedIcon{
  display: none;

}
// .DSA_wb_card-widget.mat-card .DSA_matCard-imageCtr .mat-card-image:first-child {
//   margin-top: 0;
// }
app-lifes-good-card-view .DSA_wb_card-widget.mat-mdc-card .DSA_matCard-imageCtr .mat-mdc-card-image {
  width: auto !important;
  margin: 0;
}
app-stay-updated .DSA_wb_card-widget.mat-mdc-card .DSA_matCard-imageCtr .mat-mdc-card-image {
  width: auto !important;
  margin: 0;
}
.DSA_wb_card-widget.mat-mdc-card .DSA_matCard-imageCtr .mat-mdc-card-image {
  width: 100%;
  margin: 0;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.DSA_wb_card-widget.mat-mdc-card .mat-card-header-text {
  width: 100%;
  margin: 0;
}
.DSA_wb_h5Header {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "myriad-pro", Arial !important;
  font-style: normal;
}
.DSA_wb_card-widget-02.DSA_wb_card-withprimarybg .mat-mdc-card-subtitle {
  margin-bottom: 8px;
}
.DSA_wb_card-widget.mat-mdc-card .mat-mdc-card-subtitle {
  margin-bottom: 24px;
}
.DSA_wb_card-widget.mat-mdc-card .mat-mdc-card-content {
  position: relative;
  min-height: 40px;
  margin-bottom: 24px;
}
.DSA_wb_customCarousel .owl-item .DSA_wb_card-widget {
  margin: 1px;
}
.DSA_wb_card-widget-02.DSA_wb_card-withprimarybg {
  background: #8626C3;
  width: 274px;
  height: 304px;
  padding: 0 16px 8px;
}
.DSA_wb_card-widget-02.mat-mdc-card {
  border-radius: 16px !important;
  padding: 16px;
}
.prev-icon-01{
  background: url('/assets/images/caurosel/icon-prev-01.png') no-repeat center; 
}
.next-icon-01{
  background: url('/assets/images/caurosel/icon-next-01.png') no-repeat center; 
}

.pre-icon{
  background-image: url('/assets/images/cust-carousel-left.png');
}
.nex-icon{
  background-image: url('/assets/images/cust-carousel-right.png');
}

.DSA_wb_card-widget-02.DSA_wb_card-withgreybg:hover .DSA_wb_card-footer {
  visibility: visible;
  opacity: 1;
}
.DSA_wb_card-widget-02.DSA_wb_card-withgreybg .DSA_wb_card-footer {
  width: 100%;
  height: 24px;
  margin: 8px 0 0; 
  transition: all 0.2s ease 0.2s;
}
.DSA_wb_card-widget-02.DSA_wb_card-withgreybg .icon-withText {
  line-height: 24px;
}

.DSA_panelBG {
    border-radius: 16px;
}
.DSA_circletext{
  background: $bg-primary-80;
  color:$text-white;
  height:20px;
  width:20px;
  display: inline-block;
  line-height: 20px;
  border-radius: 50%;
  font-size: $size-hd-10;
  font-style: normal;
  position: absolute;
  right:0;
  top:5px;
}
// .DSA_wb_Icon-btn{
//   @extend .DSA_wb_innerPrimary;
//   color: $text-primary-80;
//   font-family: $font-style-1; 
//   border-radius: 4px!important;
//   line-height: 16px !important;
//   padding: 2px 10px 0px 0!important;
//   font-weight: $font-400;
//     span.icon_btnCont{
//       margin-right: 5px;
//       background-color: $bg-white;
//       border-radius: 4px;
//       @extend %default-shadow;
//       padding: 4px;
//       background-position: center center;
//       display: inline-block;
//       vertical-align: middle;      
//     }
//     &:hover{
//       span.icon_btnCont{        
//         box-shadow: none!important;
//       }
//       @extend %hover-shadow;
//     }    
//     &:focus{
//       outline: none !important;
//     }
// }

// .DSA_wb_text-btn{
//   @extend .DSA_wb_primary;   
//   font-family: $font-style-1; 
//   color: $text-primary-80;
//   line-height: 20px !important;
//   font-size: $wb-button-lg;
//   font-weight: $font-400;
//   background: none;
//   min-width: auto !important;
//   padding: 8px 16px !important;
//     &.mat-flat-button{
//       border-radius:0 !important;
//     }
//     &:hover{
//       background:  $bg-primary-50;
//     }
//     &:active{   
//       background: $bg-primary-60;
//     }
//     &:disabled{
//     color: $text-neutral-50;  
//     }
//     &:focus{
//       outline: none !important;
//     }
//     &:disabled, &:disabled:active, &:disabled:hover{
//       box-shadow:none !important;
//     }
//   }
  .DSA_panelrounded{
    border-radius: $border-radius-16 !important;
  }
  
  .DSA_panelBG{ 
    border-radius: $border-radius-16;
  }

  .Group_8820-icon{
    background: url("/assets/images/Group_8820.svg") no-repeat center center;

    padding: 57px 293px 50px 0px;
margin: 0px -59px 0px 21px;
}
.Group_8823-icon{
    background: url("/assets/images/Group_8823.svg") no-repeat center center;

    padding: 90px 110px 43px 28px;
}
.Group_9845-icon{
  background: url("/assets/images/Group 9845.png") no-repeat center center;

  padding: 55px 110px 43px 28px;
}
.Group_10948-icon{
  background: url("/assets/images/Group 10948.png") no-repeat center center;

  padding: 55px 110px 43px 28px;
}
.Group_10932-icon{
  background: url("/assets/images/Group 10932.png") no-repeat center center;

  padding: 55px 110px 43px 28px;
}
.Group_8832-icon{
    padding: 55px 110px 43px 28px;

    background: url("/assets/images/Group_8832.svg") no-repeat center center;
}
.GroupIcon10822{
  background: url("/assets/images/GroupIcon10822.svg") no-repeat center center;
  height: 96px;
  width: 96px;
  margin: 0 auto;
}
.GroupIcon10824{
  background: url("/assets/images/GroupIcon10824.svg") no-repeat center center;
  height: 96px;
  width: 96px;
  margin: 0 auto;
}

.ahd-icon{
  height: 24px;
  width: 24px;
  background: url("/assets/images/icon_AHD.svg") no-repeat center center;
}
.Group_10831-icon{
  height: 96px;
  width: 96px;
  margin: 0 auto;
  background: url("/assets/images/Group _10831.svg") no-repeat center center;
}
.DSA_popoverMainCtr{
  position: relative;
  display: inline-block;
  }
  .DSA_popoverMain{
    display: none;
  }
  .DSA_popoverMainBody{
    @extend %raised-shadow;
    border:0;
    width:372px;
    position: absolute;
  
    z-index:9999;
    background: $bg-white;
   
  }
  .DSA_showpopup{
    display: block;
  }
  
  .DSA_popoverMainBody:before {
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;   
    border-right:8px solid $bg-neutral-50; 
    content:'';
    position: absolute;
    left:-10px;
    top:10px;
  
  }
  .DSA_popoverMainBody:after {
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;   
    border-right:8px solid $bg-white; 
    content:'';
    position: absolute;
    left:-8px;
    top:8px;
  
  }
  
  .DSA_popoverMainBody.DSA_leftArrow:before {
    top:50%;
    right:-10px;
    left:auto;
    border-left:8px solid $bg-neutral-50; 
    border-right:none; 
  }
  .DSA_popoverMainBody.DSA_leftArrow:after {
    top:50%;
    right:-8px;
    left:auto;
    border-left:8px solid $bg-white; 
    border-right:none; 
    
  }
  .DSA_popoverMainBody.DSA_rightArrow:before {
    top:50%;
    left:-10px;
    right:auto;
  }
  .DSA_popoverMainBody.DSA_rightArrow:after {
    top:50%;
    left:-8px;
    right:auto;
  }
  
  .DSA_popoverMainBody.DSA_bottomArrow:before { 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;  
    border-bottom: 10px solid $bg-neutral-50; 
    border-top:none; 
    left:50%;
    top:-10px;
    bottom: auto;
  }
  .DSA_popoverMainBody.DSA_bottomArrow:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;  
    border-bottom: 10px solid $bg-white;
    border-top:none; 
    left:50%;
    top:-8px;
    bottom: auto;
    
  }
  
  .DSA_popoverMainBody.DSA_topArrow:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;  
    border-top: 10px solid $bg-neutral-50; 
    border-bottom:none; 
    left:50%;
    top:auto;
    bottom:-10px;
  }
  .DSA_popoverMainBody.DSA_topArrow:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;  
    border-top: 10px solid $bg-white; 
    border-bottom:none; 
    left:50%;
    top:auto;
    bottom:-8px;
  }
  .DSA_popover.popover{
    @extend %raised-shadow;
    border:0;
    width:372px;
  }
  .DSA_popover .popover-body{
    padding:0;  
  }
  .DSA_popoverTitle{
    padding: 16px 16px 0px 24px;
    font-family: $font-style-2;
    font-size: $size-button-18;
    color:$text-black;
    border:0;
  }
  .DSA_popoverCnt{
    padding: 24px;
  }
  .DSA_popoverFooter{
    @extend %footer-shadow;
    padding: 16px 24px;
  }
  .DSA_popoverclose{
    float: right;
  }
  .DSA_popoverRM{
  margin-right: -8px;
  margin-top: -8px;
  }



// .DSA_popovermid:before, .DSA_popoverMainBody:after {
//   border: 0 !important;
// }
.DSA_popoverMainBody:before {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #DADADA;
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
}

.DSA_popoverCnt {
  padding: 24px;
}

.DSA_popoverFooter {
  padding: 16px 24px;
}




.joyride-step__container {
  box-sizing: border-box;
  position: relative;
  color: #000;
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 0 30px 1px #000;
  width: 360px !important;
  max-width: 360px!important;
  min-width: 360px!important;
}


.joyride-step__container {
  border-radius: 16px;
  padding: 0 !important;
}

.web-hoverShadow, .joyride-step__container, .DSA_wb_customSlider .ng5-slider .ng5-slider-pointer:hover, .DSA_wb_custom-dropdown.dropdown-menu, .DSA_wb_custom-pagination .page-link:hover, .option_ul li a:hover, .DSA_wb_card-widget.mat-mdc-card:hover, .DSA_wb_custom-table tbody tr.active, .DSA_wb_custom-table tbody tr:hover, .mat-mdc-chip.mat-mdc-standard-chip.DSA_wb_chipStyle-Normal:hover, .mat-mdc-chip.mat-mdc-standard-chip.DSA_wb_chipStyle-Big:hover, .DSA_wb_miniPrimary-btn:hover, .DSA_wb_Icon-btn:hover, .DSA_wb_innerSecondary-btn:hover, .DSA_wb_innerPrimary-btn:hover, .DSA_wb_secondary-btn:hover, .DSA_wb_primary-btn:hover {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.32) !important;
}

.joyride-step__close::after {
  content: "Skip";
  font-size: 12px;
  font-weight: 400;
  font-family: "roboto", Arial, sans-serif !important;
  font-style: normal;
  color: #737373;
}

.joyride-step__close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.joyride-step__close {
  width: 30px !important;
  top: 20px !important;
}



.joyride-step__header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px;
}

.joyride-step__header {
  padding: 24px 24px 12px 24px !important;
}

.joyride-step__title {
  font-weight: 700;
  font-size: 20px;
}

.joyride-step__body {
  text-align: left;
  padding: 10px 8px;
}

.joyride-step__body {
  padding: 16px 24px 24px 24px !important;
}

.DSA_wb_mainBodyTxt2 {
  font-size: 14px;
  font-weight: 400;
  font-family: "roboto", Arial, sans-serif !important;
  font-style: normal;
}

.joyride-step__footer {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 8px;
}

.joyride-step__footer {
  padding: 8px 24px !important;
  position: relative;
}

.joyride-step__footer, .DSA-Panelactions-Btn, .DSA_popoverFooter, .DSA_panelFooter {
  box-shadow: 0px -2px 1px 0px rgba(204, 204, 204, 0.2);
}
.joyride-step__counter-container {
  margin-right: 10px;
}

joyride-step__counter {
  margin-left: 22px;
  margin-top: 2px;
}

.joyride-step__counter {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "roboto", Arial, sans-serif !important;
  font-style: normal;
  color: #737373;
}

.joyride-step__buttons-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}


.joyride-step__button:last-child {
  margin-left: 2.5px;
}

.joyride-step__button:first-child {
  margin-right: 2.5px;
}


// .DSA_wb_text-btn.mat-flat-button {
//   border-radius: 0px !important;
// }
.mat-mdc-unelevated-button:not([class*=mat-elevation-z]), .mat-mdc-outlined-button:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
}


/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
  opacity: 0;
  transition: opacity .2s cubic-bezier(.35,0,.25,1),background-color .2s cubic-bezier(.35,0,.25,1);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay, .mat-button-ripple.mat-ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
  background: #000;
}

.joyride-step__close svg {
  display: none;
}

.DSA_popoverMainBody {
  border: 0;
  width: 372px;
  position: absolute;
  z-index: 9999;
  background: #ffffff;
}

.DSA_popovermid {
width: 425px;
border-radius: 16px;
top: 24px;
left: 50%;
margin-left: -200px;
position: absolute;
}
.DSA_RightUtil li.DSA_active a.DSA_mainactiveLink, .DSA_RightUtil li.DSA_active span.DSA_profile.DSA_mainactiveLink {	
  background: #5e1f89;
    border-radius: 5px;
  
  
  
  }
  .smallProfile-icon{
    padding: 55px 110px 43px 28px;

    background: url("/assets/images/profilesmall.png") no-repeat center center;
}

@media (max-width: 1200px)
{.clsFull {
  display: none !important;
}
}

.joyride-step__prev-container {
    position: absolute;
    left: 22px;
    top: 18px;
}


.joyride-step__counter {
  margin-left: 22px;
  margin-top: 2px;
}

.joyride-step__counter {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: "roboto", Arial, sans-serif !important;
  font-style: normal;
  color: #737373;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.DSA_wb_custom-table tbody tr td .mat-form-field-label-wrapper , .dispBlock{
  display:block !important;;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.DSA_wb_custom-table tbody tr td .mat-form-field-appearance-legacy .mat-form-field-infix
{
  //padding: 0.4375em 0 !important;
  border-top: .84375em solid transparent  !important;
}
.DSA_starIcons_ul{
  list-style: none;
  li{
    margin: 0;
    float: left;
    a{
      width: 24px;
      height: 24px;
      display: block;
      cursor: pointer;
    }    
  } 
} 
.storiesPickedNodata{
  background: url("/assets/images/storiesPicked_Nodata.svg") no-repeat center center;
  padding: 50px 100px 45px 7px;
}

.sp2_toggleBookmarkOn{
  background: url('/assets/images/sp2_toggleBookmarkOn.png') no-repeat center center;  
}
.icon-show{
  background: url('/assets/images/icon-show.svg') no-repeat center center;  
}
.icon-download-dark{
  background: url('/assets/images/icon-download-dark.svg') no-repeat center center;  
}

.DSA_wb_card-widget.DSA_card-square.DSA_square-lg {
    min-width: 172px;
    max-width: 172px;
}

.DSA_wb_card-widget.DSA_card-square {
    min-width: 100px;
    max-width: 100px;
    float: left;
}
.DSA_wb_card-widget.mat-mdc-card {
    padding: 11px;
    min-height: 50px;
    border-radius: 16px;
}

.v-allignMid{
  vertical-align: middle;
}
.lessMoreDSA_popoverMainCtr{
  position: relative;
  display: inline-block;
}
.lessMoreDSA_popoverMain {
  display: none;
  position: absolute;
  right: -16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 16px #0000003D;
  width: 56px;
  border-radius: 10px;
  height: 117px;
  z-index: 999999;
}
.lessMoreDSA_popoverMainCtr:hover .lessMoreDSA_popoverMain {display: block;}
.mBlueIcon{
  width: 16px;
  height: 16px;
  background-color: #3367D6;
  border-radius: 50%;
  margin: 0 auto;
}
.lRedIcon{
  width: 8px;
  height: 8px;
  background-color: #B71C1C;
  border-radius: 50%;
  margin: 0 auto;
}
.grySep{
  height: 1px;
  background-color: #ececec;
}
.lessMoreDSA_popoverCnt .more, .lessMoreDSA_popoverCnt .less{
  cursor: pointer;
}
.lessMoreDSA_popoverCnt .more:hover{
  background-color: #efefef;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
} 
.lessMoreDSA_popoverCnt .less:hover{
  background-color: #efefef;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.Group_10575{
  background: url('/assets/images/Group_10575.png') no-repeat center center;
}
// .Group_10574{
//   background: url('/assets/images/Group_10574.png') no-repeat center center;
// }


.DSA_panelBGBody_coviddetails{
  border-radius: $border-radius-16;
  background: url('/assets/images/covid-details.svg') no-repeat top left;
  background-size: cover;
}


@media screen and (max-width: 395px){
  
  .instore{
    display: none;
  }

  .instoremob{
    display: block;
  }

  .contsingleegg {
     padding-left: 0px; 
     padding-top: 0px;
    text-align: center;
    width: 60%;
}
}

.instore{
  display: block;
}

.instoremob{
  display: none;
}


.DSA_listItemSingle li > a:hover {
  text-decoration: none;
  color: #737373;
  background-color: #EFEFEF;
}


@media screen and (max-width: 710px){
  .mobview.float-right{
    float: none !important;
    margin: 0px 0px 0px 22px !important;
    padding-top: 26px im !important;
  }

  .mTop52{
    margin-top: 24px;
  }
}

.mobview{
  float: right;
}

.DSA_wb_listItemSingle, .DSA_wb_normalListItem {
  list-style: none;
  padding:0px;
  margin:0;
}
.DSA_wb_listItemSingle li, .DSA_wb_normalListItem li{
  padding: 0px;
  margin: 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d; 
  position: relative;
}
.DSA_wb_listItemSingle li>a{
  padding: 16px;
  display: block;
  position: relative;  
  border-left: 4px solid transparent;
  -webkit-transition: all 280ms linear;
  -moz-transition: all 280ms linear;
  -o-transition: all 280ms linear;
  -ms-transition: all 280ms linear;
  transition: all 280ms linear;
  h2{
    line-height: 24px; 
    margin-left: 40px; 
  }
  h2.menu-withoutIcon{
    margin-left: 0;
  }
  i.icon.icon_Pin_filled{
    display: inline;
  }
}
.DSA_wb_listItemSingle li>a:hover{
  text-decoration: none;
  color: $text-neutral-80;  
  background-color: $bg-neutral-40;
  h2, i.icon{
    color: $text-neutral-80;
  }  
  i.icon.icon_Pin{
    display: inline;
  }  
}
.DSA_wb_listItemSingle li>a:active{
  text-decoration: none;  
  color: $text-primary-100;
  background-color: $bg-neutral-50; 
  h2, i.icon{
    color: $text-primary-100;
  }  
}
.DSA_wb_listItemSingle li.active>a{
  background-color: transparent;
  //border-left:4px solid $bg-primary-80;
  h2{
    color: $text-primary-80;
    font-weight: $font-600;
    font-style: normal;
  }
  i.icon{
    color: $text-primary-80;    
  }  
}
.DSA_wb_listItemSingle li.activeDark>a{
  h2{
    color: $text-black;
    font-weight: $font-600;
    font-style: normal;
  }
  i.icon{
    color: $text-black;
  }
}

.DSA_list-1line{
  line-height: 24px;
}
.DSA_wb_listItemSingle.DSA_img32  li > a{
  padding:12px 16px;

    .DSA_list-1line{
      line-height:32px;
    }
}

.DSA_secLevel{
  li{
    a{
      padding-left: 24px;

    }
  }
}
.DSA_wb_sec_listItemSingle{  
  li > a, li > span{    
    padding: 8px 16px;
    border: none;
    display: block;
    position: relative;
  }
  li.active{
    a{
      border: none;      
    }
  }  
}

.DSA_wb_topMenu{
  li{
    display: inline-block;
    position: relative;
    a{
      border: none;
      border-bottom: 4px solid transparent;
      padding: 0 24px;
      h2{
        line-height: 76px;
        color:$text-neutral-80;
      }
    }
    ul.DSA_wb_listItemSingle.DSA_secLevel{
      position: absolute;
      top: 80px;
      left: 0;
      width: 240px;
      background: $bg-white;
      @extend %default-shadow;
      z-index: 1;
      li{
        display: block;
        a{
          padding: 16px;
          border: none;          
        }
        h2{
          line-height: 24px;
          margin: 0;
        }
      }
      li.active{
        a{
          border: none;      
        }
      }
    }
  }
  li.active{
    a{
      border: none;
      border-bottom: 4px solid $bg-primary-80;
      h2{
      /*color:$text-white;*/
      }
         
      
    }
  }
  li:hover{
    ul.DSA_wb_listItemSingle.DSA_secLevel{
      display: block;
    }
  }
}
.DSA_wb_listItemSingle.DSA_wb_topMenu li.activeDark>a{
  border: none;
  border-bottom: 4px solid $bg-primary-80;  
  h2{
    color: $text-primary-80;
  }
}
.marB120{
  margin-bottom: 120px !important;
}

.DSA_wb_listLeft{
  width:75%;
  float: left;
  padding-right:8px;
  position: relative;
}
.DSA_wb_listRight{
  width:25%;
  float: right;
  text-align: right;
  padding-left:8px;
  line-height: 24px;
  }
.DSA_wb_vertical-RightCenter{
  @include verticalCenter;
  right:0;
}
.DSA_wb_marginicon40{
  margin: 0 0 0 56px;
  display: block;
}
.DSA_wb_marginicon2{
  margin: 0 0 0 46px;
  line-height: 35px;
  display: block;
}
.DSA_wb_marginicon32{
  margin: 0 0 0 48px;
  display: block;
}
.DSA_wb_marginicon24{
  margin: 0 0 0 40px;
  display: block;
}
.DSA_wb_marginicon56{
  margin: 0 0 0 72px;
  display: block;
}
.DSA_wb_marginicon90{
    margin: 0 0 0 90px;
    display: block;
  }
.DSA_wb_normalListItem li > a, .DSA_wb_normalListItem li > span{
  padding: 8px 16px;
  border: none;
  display: block;
  position: relative;
  -webkit-transition: all 280ms linear;
  -moz-transition: all 280ms linear;
  -o-transition: all 280ms linear;
  -ms-transition: all 280ms linear;
  transition: all 280ms linear;
  .DSA_wb_lh_32{
    line-height: 32px;
  } 
  .DSA_wb_lh_56{
    line-height: 56px;
  }
  h2{
    line-height: 24px;   
  }
}
.DSA_wb_normalListItem.DSA_wb_largeList li > a{
  padding: 24px;
  background-color: $bg-neutral-30;
  border-left: 4px solid transparent;
}
.DSA_wb_normalListItem.DSA_wb_largeList li.active > a{
  background-color: $bg-primary-50;
  border-left: 4px solid $border-primary-80;
}
.DSA_wb_normalListItem li > a:hover, .DSA_wb_normalListItem li > span:hover{
  text-decoration: none;  
  background-color: $bg-neutral-40;
}
.DSA_wb_normalListItem li > a:active, .DSA_wb_normalListItem li > span:active{
  background-color: $bg-neutral-60;
  
}
.DSA_wb_listrounded  li > a:hover,.DSA_wb_listrounded li > a:active{
    border-radius: $border-radius-big;
   box-shadow: none !important;
  }

.DSA_wb_withIcon li  a:active i::before{
  color:$text-black;
}
/* .DSA_wb_normalListItem li.active > a, .DSA_wb_normalListItem li.active > span{
  background-color: $bg-neutral-50;
} */

.DSA_wb_normalListItemShadow{
  li{
      border: none;
      display: block;
      position: relative;
      transition: all 280ms linear;

    div{

      
      border-bottom: 1px solid #EFEFEF;
      &.topbor{
        border-top: 1px solid #EFEFEF;
      }
   
      a{
        padding:24px;
      
        border: none;
        display: block;
        position: relative;
        -webkit-transition: all 280ms linear;
        transition: all 280ms linear;
          .showhover{
            display: none;
          }
      }
      a:hover{
        background: none;
        @extend %hover-shadow;
        color:$text-primary-80;
      }
      a:hover .showhover{
        display: inline-block;
      }
    }
  }
}


.lineheight{
  line-height: 20px;
}


.dsa_cauroselhover>img {
    width: auto !important;
    position: absolute;
    z-index: 1;
    left: 50%;
    height:297px;
    -webkit-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
}

.DSA_middleCtr{
  display: block;
  width: 100%;
  table-layout:unset;
}
.DSA_middlecontent,.DSA_articlesection{
  display: block;
  height: auto;

}
.DSA_articlesection{
  width:100%;
  padding-left:0;
}

@media screen and (min-width:1280px){

  .DSA_middleCtr{
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .DSA_middlecontent,.DSA_articlesection{
    display: table-cell;
    vertical-align: top;
    height: 100%;
  
  }
  .DSA_articlesection{
    width:350px;
    padding-left:32px;
  }

}

.dsa_cauroselhover {
  height: 297px;
  overflow: hidden;
  border-radius: 16px;
}

.dsa_cauroselhover_smallcard{
  height: 80px !important;
}

.dsa_cauroselhover>img {
  width: auto !important;
  position: absolute;
  z-index: 1;
  left: 50%;
  height:297px;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}
//css b

.DSA_imageradius-big{
  border-radius: 8px;
} 
.DSA_imageradius-extrabig{
  border-radius: 16px;
} 


/* List variations - Start */
.listImgCont{
position: relative;
overflow: hidden;
&.size-56{
  width: 56px;
  height: 56px;    
}
&.size-80{
  width: 80px;
  height: 80px;
}
&.size-144{
  width: 144px;
  height: 144px;
}
img{
  position: absolute;
  width: auto;
  height: 100%;
  left: 50%;
  -webkit-transform: translate(-50%,0);
  transform: translate(-50%,0);
  /*border-radius: $border-radius-extrabig;*/
}
}
.DSA_wb_marginicon80{
margin: 0 0 0 96px;
display: block;
}
.DSA_wb_marginicon144{
margin: 0 0 0 160px;
display: block;
}
/* List variations - End */

.dsa_cauroselhover .DSA_wb_txt-overlay {
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
border-radius: 16px;
z-index: 2;
background: #000;
background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,.75)));
background: linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,.75));
}


.DSA-image-gradient-bg{
background: rgb(255,255,255);
background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%);
}

.DSA_wb-cus-accordion1 .mat-expansion-panel{
margin: 16px 0 !important;
border-radius: 4px !important;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
.mat-expansion-panel-header {
  height: 90px !important;
  padding: 0 16px;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  border-radius: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  


 
}
.mat-expansion-panel-header-title{
color: $grey-light
}
.mat-expansion-panel-body{
background: $white-color;
padding: 0
}


}

.DSA_wb-cus-accordion1 .mat-expanded{
.mat-expansion-panel-header {
  background: $color-dark!important;
  .mat-expansion-panel-header-title{
    color: $white-color!important
  }
}

.mat-expansion-indicator::after, .mat-expansion-panel-header-description{
  color: $white-color
}
}
.coachmark_skip {
position: absolute;
right: 0;
top: 16px;
background: white;
width: 40px;
font-size: 12px;
font-weight: 400;
font-family: "roboto", Arial, sans-serif !important;
font-style: normal;
color: #737373;
}

.dsa_cauroselhoverIcon{
  position: absolute;
  left:0;
  right:0;
  bottom:0;
  z-index: 2;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  opacity:0;
  background: rgba(0, 0, 0, 0);
}
.dsa_cauroselhover:hover .dsa_cauroselhoverIcon{
  opacity:1;
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
}
@media screen and (min-width:450px) {
  .DSA_wb_customCarousel{
    .carousel_content{
      top: 15%;
      left: 32px; 
      .DSA_wb_displayText.marB16{
        margin-bottom: 32px !important;
      }
      .DSA_wb_h1Header.marB8{
        margin-bottom: 16px !important;
      }
    }     
  }  
}
@media screen and (min-width:480px) and (max-width:550px){
  .DSA_wb_customCarousel{
    .carousel_content{
      left: 32px;  
      right: auto;  
      top: 20%;
      width: 360px;
      margin:0 auto;
      .DSA_wb_displayText.marB16{
        margin-bottom: 32px !important;
      }
      .DSA_wb_h1Header.marB8{
        margin-bottom: 16px !important;
      }
    }  
  }  
}
@media screen and (min-width:600px){
  .DSA_wb_customCarousel{
    .carousel_content{
      left: 40px;  
      right: auto;  
      top: 30%;
      width: 500px;
      margin:0 auto;
    }
  }  
}

@media screen and (min-width:768px){
  .DSA_wb_customCarousel{
    .owl-item img.banner_mobileXS,.owl-item img.banner_desktopImage{
      display: none;
    }
    .owl-item img.banner_desktop,.owl-item img.banner_tab{
      display: block;
    }   
    .carousel_overlay{
      display: none;
    }
    .carousel_content{      
      left: 36%;    
      top: 15%;
      width: 450px;      
      .DSA_wb_displayText.marB16{
        margin-bottom: 16px !important;
      }
      .DSA_wb_h1Header.marB8{
        margin-bottom: 8px !important;
      }
      .DSA_cauroselP {
        height: 96px;
        overflow: hidden;
      }      
    } 
  }    
}
@media screen and (min-width:992px){
  .DSA_wb_customCarousel{
    .carousel_content{
      top: 22%;
      width: 450px;
    }  
    .owl-item img.banner_desktop,.owl-item img.banner_tab{
      display: block;
    }
    .owl-item img.banner_desktopImage,.owl-item img.banner_mobileXS{
      display: none;
    }
  }  
}

@media screen and (min-width:1024px){
  .DSA_wb_customCarousel{

    
    .carousel_content{
      top: 15%;
      left:42%;
      width: 470px;
      .DSA_wb_displayText{
        font-size: $size-hd-48;
        font-weight: $font-600;
        font-family: $font-style-2;
      }
      .DSA_wb_h1Header{
        font-size: $size-hd-24;
        font-weight: $font-600;
        font-family: $font-style-2;
      }
      .DSA_wb_mainBodyTxt{
        font-size: $size-text-16;
        font-weight: $font-400;
        font-family: $font-style-1;
      }
    } 
  }
}
@media screen and (min-width:1200px){
  .DSA_wb_customCarousel{
    .owl-item img.banner_tab,.owl-item img.banner_mobileXS{
      display: none;
    }
    .owl-item img.banner_desktopImage,.owl-item img.banner_desktop{
      display: block;
    }   
    .carousel_content{
      top: 18%;
      .DSA_wb_displayText.marB16{
        margin-bottom: 32px !important;
      }
      .DSA_wb_h1Header.marB8{
        margin-bottom: 16px !important;
      }      
    } 
  }  
}
@media screen and (min-width:1530px){
  .DSA_wb_customCarousel{       
    .carousel_content{
      top: 30%; 
      width: 650px;     
    } 
  }
}

@media(min-width:1024px){
.DSA_modal-sml{
  
  .modal-dialog{
    min-width: 824px;
  }
}

.DSA_modal-sml{
   .modal-content  {
    border-radius: 16px;
    border: 0px solid rgba(0, 0, 0, 0.2);
    border-radius: 16px;    
}
}
}

@media screen and (min-width: 1024px){
.DSA_mainCtr {
  padding-bottom: 8px;
}
}
@media screen and (max-width: 980px) {
.sp2_reasonLeaveTxt textarea {
 width: 90%;
}



}
.icon-errorState{
  background-image: url('/assets/images/icons/default_icons/icon_errorState.svg') !important;
}
.icon-goodState{
  background-image: url('/assets/images/icons/default_icons/icon_goodState.svg') !important;
}
.icon-warningState{
  background-image: url('/assets/images/icons/default_icons/icon_warningState.svg') !important;
}
.icon_cn_blank{
  background-image: url('/assets/images/icons/default_icons/icon_cnB.svg');
  height: 75px;
  width: 70px;
  background-repeat: no-repeat
}
.DSA_wb_Icon-btn span.icon {
  margin-right: 5px;
  background-color: #fff;
  border-radius: 4px;
  padding: 12px;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 1px 4px rgba(0,0,0,.16);
}
.DSA_wb_Icon-btn:hover span.icon {
  box-shadow: none!important;
}
.pull-left {
  float: left!important;
}
// .DSA_guidedflow.DSA_wb_customTab .mat-tab-label, .DSA_guidedflow.DSA_wb_customTab .mat-tab-link {
//   height: auto!important;
// }
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.GuidedCustomFlow .mat-tab-label, .GuidedCustomFlow .mat-mdc-tab-link {
  height: auto!important;
}
.txtStyle{
  text-decoration: underline;
  cursor: pointer;
}
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.chipsCls .mat-chip-list-wrapper {  
  margin: 16px 0px 0px !important;
}


.DSA_wb_contentRightCol {  
  z-index: 9 !important;  
}

  .DSA_wb_RightColoverlay{
       width:100% !important;
       -webkit-box-shadow: -2px 0px 5px 0px rgba(126,126,126,0.2);
-moz-box-shadow: -2px 0px 5px 0px rgba(126,126,126,0.2);
box-shadow: -2px 0px 5px 0px rgba(126,126,126,0.2);
transition:right 0.2s ease 0.2s;
     }

     .DSA_rightPanelHide{
      right:-100%;
      transition:all 0.2s ease 0.2s;
      box-shadow: none !important;
      visibility: hidden !important;
    }

     @media screen and (min-width:768px){
      .DSA_wb_contentRightCol{
        background: $bg-white;
        position: fixed;
        width: 350px;
        z-index: 2;
        right: 0;
        bottom: auto;
        // @include rightpanel-shadow;
      }
      .DSA_noificationclose{
          display: block;
      }
      .DSA_noificationback{
          display: none;
          }
      .DSA_wb_contentRightCol.DSA_wb_RightColoverlay{
          width:360px !important;
      }
      .DSA_rightPanelHide{
          right:-380px !important;
          transition:all 0.2s ease 0.2s;
          box-shadow: none !important;
          visibility: hidden !important;
      }
  }

  .DSA_wb_contentRightCol{
    background: #fff;
    position: fixed;
    width: 100%;
    z-index: 2;
    right: 0;
   //  top:72px;
    @extend %wb-left-shadow;

    padding-bottom: 16px;
 
   right: 0;
   top:56px;
   bottom: 0;
   /*padding-bottom: 64px;*/
  }
  @media screen and (min-width:1280px){
    .DSA_wb_contentRightCol{
        top:80px;
      }
}
@media screen and (min-width:1024px){
  .DSA_wb_contentRightCol{
    background: $bg-white;
    position: fixed;
    width: 350px;
    z-index: 2;
    right: 0;
    top:80px;
    // @include rightpanel-shadow;
  }
  .DSA_footer{
    position: fixed;
    width:100%;
    bottom:0;
    z-index: 9;
  }

 }
.web-SecondaryRed {
	color: #B71C1C;
  background: initial !important;
}

.DSA_wb_customCarousel.customCar .owl-theme .owl-dots .owl-dot.active span {
  background: #8626c3!important;
  border: 1px solid #8626c3;
}
.DSA_wb_card-widget.mat-mdc-card .DSA_wb_flex {
  display: flex;
  grid-template-columns: 80% 20%;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.checkboxMargin .mat-checkbox-inner-container{
  margin:4px 4px auto !important;
  vertical-align: top !important;
}
.cusAccNotification .mat-expansion-panel-body{
  background: transparent !important;
  background-color: transparent !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
app-pulse .customPulseCheckbox .mat-checkbox-layout{
  white-space: normal!important;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-inner-container{
    top:2px;
    position: relative;
    margin: 0px 8px 0px auto;    
    }
  }
  .DSA_popoverwidth.popover{
    width: auto !important;
    max-width: 394px!important;
  }
.mat-mdc-chip.mat-mdc-standard-chip.DSA_wb_chipStyle-Normal,
.mat-mdc-chip.mat-mdc-standard-chip.DSA_wb_chipStyle-Big{
  cursor: pointer !important;
}
@media(min-width:1024px){
  .DSA_modal-xsml{
    .modal-dialog{
      min-width: 500px;
    }
  }
  .DSA_modal-xsml-border{
     .modal-content  {
      border-radius: 16px;
      border: 0px solid rgba(0, 0, 0, 0.2);
}
  }
}
@media (min-width: 576px){
  .DSA_modal-xsml{
    .modal-dialog{
      min-width: 500px;
      border-radius: 16px;
    }
  }
  .DSA_modal-xsml-border{
     .modal-content  {
      border-radius: 16px;
      border: 0px solid rgba(0, 0, 0, 0.2); 
    }
  }
}
.modal-content{
  border-radius: 16px !important;
}
app-event-details .custom-table table{
  width: 100%;
  margin: 0 0 16px;
  thead{
    tr{
      th{
        font-family: $font-style-1;
        font-size: $size-text-14;
        font-weight: $font-500;
        font-style: normal;
        color: $text-black;
        padding: 0 8px 16px;
        vertical-align: top;
      }
      td{
        padding: 0 8px 16px;
        vertical-align: top;
      }
    }
  } 
  tbody{    
    border-radius: $border-radius-small;
    tr{
      td{
        font-family: $font-style-1;
        font-size: $size-text-14;
        font-weight: $font-400;
        font-style: normal;
        color: $text-black;
        padding: 4px;
        border-top: 1px solid $bg-neutral-40;
        height: 40px; 
        white-space: normal;
        .td_nonEditable{
          display: block;
          transition:display 0.2s ease 0.2s;
        }
        .td_editable{
          display: none;
          max-width: 220px;
          transition:display 0.2s ease 0.2s;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-legacy .mat-form-field-wrapper{
          padding: 0;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-subscript-wrapper, .mat-form-field-label-wrapper{
          display: none;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-legacy .mat-form-field-underline{
          bottom: 0;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-appearance-legacy .mat-form-field-infix{
          padding: 0;
          border: none;
        }        
      
      }  
    }
    tr:first-child{
      td{
        border-top: 1px solid $bg-neutral-50;
      }
    }
    tr:last-child{
      td{
        border-bottom: 1px solid $bg-neutral-50;
      }
    }
    tr:hover{
      background: $bg-primary-50;  
    }
    tr.active{
      background: $bg-white;
      @extend %hover-shadow; 
      td{
        .td_editable{
          display: block;
        }
        .td_nonEditable{
          display: none;
        }
      }
    }      
  } 
  &.noBor{
    border:0;
    tbody{    
      border-radius: $border-radius-small;
      tr{
        td{
          border:0;
        }
      }
    }
  }    
}